<script>

    import {layout} from "../../store";


</script>

<style>

 .layout {
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   align-items: center;
   background-color: #F9F9F9;
   border-radius: 8px 8px 0 0;
 }

 svg {
   /*display: none;*/
   /*padding: 8px 11px;*/
   padding: 0 11px 0 12px;
   margin-top: 4px;
   /*border: 1px solid red;*/
 }

 h2 {
   color: #35A4A7;
   font-size: 3.0rem;
   font-weight: 400;
   line-height: 1.6;
   margin: 0;
   /*border: 1px solid red;*/
 }


</style>

<div class="layout">

    <div>

        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="31.65" viewBox="0 0 36 31.65"><path d="M127.776,136.568a21.951,21.951,0,0,0-.542,31.038,1.045,1.045,0,1,0,1.5-1.452,19.86,19.86,0,0,1,.49-28.082,1.045,1.045,0,0,0-1.452-1.5Zm22.595,31.064a21.951,21.951,0,0,0,.542-31.038,1.045,1.045,0,1,0-1.5,1.452,19.86,19.86,0,0,1-.49,28.082,1.045,1.045,0,0,0,1.452,1.5Zm-19.73-26.051a14.982,14.982,0,0,0-.37,21.185,1.045,1.045,0,1,0,1.5-1.452,12.892,12.892,0,0,1,.318-18.229,1.045,1.045,0,0,0-1.452-1.5Zm16.864,21.038a14.982,14.982,0,0,0,.37-21.185,1.045,1.045,0,1,0-1.5,1.452,12.891,12.891,0,0,1-.318,18.229,1.045,1.045,0,1,0,1.452,1.5Zm-14-16.026a8.014,8.014,0,0,0-.2,11.331,1.045,1.045,0,0,0,1.5-1.452,5.923,5.923,0,0,1,.146-8.375,1.045,1.045,0,0,0-1.452-1.5Zm11.133,11.013a8.014,8.014,0,0,0,.2-11.331,1.045,1.045,0,1,0-1.5,1.452,5.923,5.923,0,0,1-.146,8.375,1.045,1.045,0,0,0,1.452,1.5Zm-7.5-7.77a2.787,2.787,0,1,1-.069,3.941A2.789,2.789,0,0,1,137.137,149.837Z" transform="translate(-121.073 -136.275)" fill="#35a4a7" fill-rule="evenodd"/></svg>

    </div>

    <div>

        <!-- #LM -->
        <h2 style="font-size: {$layout.frameTitleFontSize}rem">Liveticker</h2>

    </div>

</div>