<script>

    import {createEventDispatcher} from "svelte";

    import SettingsRadioButton from './SettingsRadioButton.svelte';

    import version from '../../version.json';

    const dispatch = createEventDispatcher();

    export let layoutStyle;
    export let clientsFormat;
    export let mapColorStyle;
    export let mapCoverStyle;
    export let localeId;
    // export let locDesc;

    function handleClosePanelClick() {
        dispatch('close_settings', {});
    }

    function getVersionLabel() {

        let versionLabel = `${version.buildMajor}.${version.buildMinor}.` +
            version.buildRevision;

        if (version.buildTag !== '') {
            versionLabel += ' ' + version.buildTag;
        }

        return versionLabel;
    }

</script>

<style>

    .panel {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      padding: 12px 20px;
      background-color: white;
      -webkit-box-shadow: -2px 4px 10px -4px rgba(0,0,0,0.8);
      box-shadow: -2px 4px 10px -4px rgba(0,0,0,0.8);
    }

    .header {
      /*font-size: 2.4rem;*/
    }

    .header .title {
      display: block;
      font-size: 2.4rem;
    }

    .version-info {
      display: block;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1;
      padding-top: 4px;
    }

    .close-panel-icon-button {
      position: absolute;
      top: 7px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 50%;
      background-color: white;
    }

    .close-panel-icon-button:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .close-panel-icon-button > svg {
      opacity: 0.6;
    }

    .close-panel-icon-button:hover > svg {
      opacity: 0.9;
    }

    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      padding: 4px 16px;
      margin: 20px 0;
    }

    .options {
      display: flex;
      flex-direction: row;
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      /*padding-top: 12px;*/
      margin-top: 20px;
    }

    .footer button {
      min-width: 140px;
    }

</style>

<div class="panel">

    <button
            type="button"
            class="close-panel-icon-button"
            title="Schließen"
            on:click={handleClosePanelClick}
    >

        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>

    </button>

    <div class="header">

        <!-- #LM -->

        <span class="title">Einstellungen</span>

        <span class="version-info">

            (Liveticker Version: {getVersionLabel()})

        </span>

    </div>

    <div class="body">

        <fieldset>

            <!-- #LM -->
            <legend>Layout</legend>

            <div class="options">

                <!-- #LM -->
                <SettingsRadioButton
                        label="Stil A"
                        bind:group={layoutStyle}
                        settingsName="layoutStyle"
                        value={0}
                        on:settings_change
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Stil B"
                        bind:group={layoutStyle}
                        settingsName="layoutStyle"
                        value={1}
                        on:settings_change
                />

            </div>

        </fieldset>

        <fieldset>

            <!-- #LM -->
            <legend>Darstellung der Organisationen</legend>


            <div class="options">

                <!-- #LM -->
                <SettingsRadioButton
                        label="Text"
                        bind:group={clientsFormat}
                        settingsName="clientsFormat"
                        value={0}
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Logos"
                        bind:group={clientsFormat}
                        settingsName="clientsFormat"
                        value={1}
                />

            </div>

        </fieldset>

        <fieldset>

            <!-- #LM -->
            <legend>Farbstil der Karte</legend>

            <div class="options">

                <!-- #LM -->
                <SettingsRadioButton
                        label="Standard"
                        bind:group={mapColorStyle}
                        settingsName="mapColorStyle"
                        value={0}
                        on:settings_change
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Lite"
                        bind:group={mapColorStyle}
                        settingsName="mapColorStyle"
                        value={1}
                        on:settings_change
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Chromatisch"
                        bind:group={mapColorStyle}
                        settingsName="mapColorStyle"
                        value={2}
                        on:settings_change
                />

            </div>

        </fieldset>

        <fieldset>

            <!-- #LM -->
            <legend>Kartenabdeckung</legend>

            <div class="options">

                <!-- #LM -->
                <SettingsRadioButton
                        label="Weiß"
                        bind:group={mapCoverStyle}
                        settingsName="mapCoverStyle"
                        value={0}
                        on:settings_change
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Hell"
                        bind:group={mapCoverStyle}
                        settingsName="mapCoverStyle"
                        value={1}
                        on:settings_change
                />

                <!-- #LM -->
                <SettingsRadioButton
                        label="Dunkel"
                        bind:group={mapCoverStyle}
                        settingsName="mapCoverStyle"
                        value={2}
                        on:settings_change
                />

            </div>

        </fieldset>

        <fieldset>

            <legend>Sprache</legend>

            <div class="options">

                <SettingsRadioButton
                        label="DE"
                        bind:group={localeId}
                        settingsName="localeId"
                        value={'de'}
                        on:settings_change
                />

                <SettingsRadioButton
                        label="FR"
                        bind:group={localeId}
                        settingsName="localeId"
                        value={'fr'}
                        on:settings_change
                />

                <SettingsRadioButton
                        label="IT"
                        bind:group={localeId}
                        settingsName="localeId"
                        value={'it'}
                        on:settings_change
                />

            </div>

        </fieldset>

    </div>

    <div class="footer">

        <div>


        </div>

        <div>

            <button
                    type="button"
                    on:click={handleClosePanelClick}
            >

                Schließen

            </button>

        </div>

    </div>

</div>
