<script>

    import {createEventDispatcher, onMount, onDestroy} from "svelte";
    import {initMap, removeMap} from '../../services/map-controller';

    import ZoomControls from './ZoomControls.svelte';

    import {layout} from "../../store";

    const dispatch = createEventDispatcher();

    const MAP_CONTAINER_EL_ID = 'liveticker_map_container';

    export let isMapboxGLSupport;
    export let mapStyleUrl;
    export let localeId;
    export let locDesc;

    onMount(() => {

        if (isMapboxGLSupport) {

            initMap(APP_CONFIG.MAPBOX.ACCESS_TOKEN, mapStyleUrl,
                MAP_CONTAINER_EL_ID, localeId);

            dispatch('init_map_completed', {});
        }
    });

    onDestroy(() => {

        removeMap();
    });

</script>

<style>

  .outer-map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .map-container-semi-rounded {
    border-radius: 0 8px 8px 0;
    /*border: 1px solid red;*/
  }

  .map-container-all-rounded  {
    border-radius: 8px;
  }

  .no-mapboxgl-support-info > div {
    padding: 20px 30px;
  }

</style>

<div class="outer-map-container">

    {#if isMapboxGLSupport}

        <ZoomControls
            {locDesc}
        />

        <!-- MAPBOX MAP CONTAINER -->

        <div
            id={MAP_CONTAINER_EL_ID}
            class={$layout.showClients ? 'map-container-semi-rounded' : 'map-container-all-rounded'}
            style="width:{$layout.mapWidth}px;height:{$layout.mainHeight}px"
        >
        </div>

    {:else}

        <div class="no-mapboxgl-support-info">

            <div>

                <div class="warning-msg">
                    <!-- #LM -->
                    Sorry, the map cannot be displayed on this device
                    because it doesn't support MapboxGL.<br />
                    Please contact your system administrator.
                </div>

            </div>

        </div>

    {/if}

</div>
