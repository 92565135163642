import {httpGet} from "./util";

let i18nDescriptions;

// Default localized descriptions
// Local descriptions must be added here as well as in i18n-descriptions.json,
// although redundant it makes i18n handling cleaner. The `defaultLocDesc`
// values are used to gather all descriptions and as fallback.
const defaultLocDesc = {

    // General and Common
    home_page: 'Home Page',
    news: 'News',
    reload_page: 'Reload Page',
    close: 'Close',

    // Date/Time
    weekdays_short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],

    // Clients
    all_organizations: 'All Organizations',
    no_logo_file_url: 'No Logo File/URL',
    clients_reset_info: 'Click in this area to highlight all organizations',
    no_campaigns_info: 'No campaigns are planned for today',

    // Statistics
    teams: 'Teams',
    employee_days: 'MAT',
    donors: 'Donors',
    donors_approved: 'Donors approved',
    donors_amount: 'Donors Amount',

    average: 'Average',
    total: 'Total',
    top_performers: 'Top Performers',
    top_donors_approved: 'Donors Approved',
    top_totals: 'Totals',
    top_highest: 'Highest',
    currency_symbol: 'CHF',

    // Map Controls
    zoom_country_bounds: 'Zoom Country Bounds',
    zoom_highlighted_clients: 'Zoom Highlighted Organizations',
    zoom_out: 'Zoom Out',
    zoom_in: 'Zoom In',

    // Settings
    open_settings: 'Open Settings'
};

async function getLocalizedDescriptions(localeId) {

    if (!i18nDescriptions) {

        // console.log('#### Loading i18nDescriptions');

        // const url = APP_CONFIG.API.URL_ORIGIN +
        //     '/js/liveticker/i18n-descriptions.json';

        const url = '/js/liveticker/i18n-descriptions.json';

        const isInitialRequest = true;
        i18nDescriptions = await httpGet(url, isInitialRequest);
    }

    const locDescKeys = Object.keys(defaultLocDesc);

    const locDescItems = {};

    for (const key of locDescKeys) {

        let desc = '';

        if (i18nDescriptions.hasOwnProperty(key) &&
            i18nDescriptions[key].hasOwnProperty(localeId)) {

            desc = i18nDescriptions[key][localeId];
        }

        // Use default value if description is missing or an empty string.
        // Most values are strings, weekdays_short are for example an array
        if (typeof desc === 'string' && desc === '') {

            //  Use default description
            desc = defaultLocDesc[key];

            // Show warning in console
            const msg = 'Missing description in i18n-descriptions.json:\n' +
                `"${key}.${localeId}"`;

            console.warn(msg);
        }

        locDescItems[key] = desc;
    }

    return locDescItems;
}

export {
    defaultLocDesc,
    getLocalizedDescriptions
};
