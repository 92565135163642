<script>

    import {createEventDispatcher, onMount} from 'svelte';

    import TextList from './text-list/TextList.svelte';
    import LogosList from './logos-list/LogosList.svelte';

    import {layout} from "../../store";

    const dispatch = createEventDispatcher();

    export let clients;
    export let nofClients;
    export let activeClientCode;
    export let showLogos;
    export let nofLocations;
    // export let mapColorStyle;
    // export let mapCoverStyle;
    export let locDesc;

    // function getClientsBgColor(mapColorStyle, mapCoverStyle) {
    //
    //     const combinedStyle = mapColorStyle + '-' + mapCoverStyle;
    //
    //     switch (combinedStyle) {
    //
    //         // case '0-0':
    //         case '1-0':
    //         case '2-0':
    //             return '#F9F9F9';
    //
    //         case '0-0':
    //         case '0-1':
    //         case '0-2':
    //         case '1-1':
    //         case '1-2':
    //             return '#FEFEFE';
    //
    //         case '2-1':
    //             return '#fefefe';
    //
    //         case '2-2':
    //             return '#F9F9F9';
    //     }
    // }

    function handleClientCodeChange(cEvt) {

        // This intercepts the dispatched `client_code_change` so that we
        // can handle any necessary zoom action.

        const nextClientCode = cEvt.detail.nextClientCode;

        dispatch('client_code_change', {
            nextClientCode: nextClientCode
        });
    }

</script>

<style>

  .layout {
    box-sizing: border-box;
    border-radius: 8px 0 0 8px;
    background-color: #FEFEFE;
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.30);
    z-index: 1;
  }

  .no-campaigns-info {
    margin: 11px 16px;
    padding-bottom: 11px;
    border-bottom: 1px dotted #858484;
    opacity: 0.7;
  }

</style>

<div
        class="layout"
        style="min-width:{$layout.clientsWidth}px;height:{$layout.mainHeight}px;"
>

    {#if nofClients === 0 && nofLocations === 0}

        <div class="no-campaigns-info">
            {locDesc.no_campaigns_info}
        </div>

    {:else if !showLogos}

        <TextList
                {clients}
                {nofClients}
                {activeClientCode}
                {nofLocations}
                {locDesc}
                on:client_code_change={handleClientCodeChange}
        />

    {:else}

        <LogosList
                {clients}
                {nofClients}
                {activeClientCode}
                {locDesc}
                on:client_code_change={handleClientCodeChange}
        />

    {/if}

</div>
