<script>

	import {beforeUpdate, onMount, onDestroy} from 'svelte';

	import {getLayoutInfo} from "./services/layout-info";

    import {
		getStatisticsData,
		getLocationsAndClientsDataPkg
	} from "./services/campaign-data";

	import {
		setMapLanguage,
	    setMapMarkers,
        highlightMapMarkers,
		closeAnyOpenMapPopup,
        zoomCountryBounds,
		setMapStyleURL,
		resizeMap
	} from './services/map-controller';

	import {debounce, getTimeLabel} from "./services/util";

	import Clock from './components/clock/Clock.svelte';
	import Header from './components/header/Header.svelte';

	import PanelFrame from './components/panel-frame/PanelFrame.svelte';

	import Clients from './components/clients/Clients.svelte';
	import Map from './components/map/Map.svelte';

	import Statistics from './components/statistics/Statistics.svelte';
	import StatisticsHeader from './components/statistics/StatisticsHeader.svelte';
	import Footer from './components/footer/Footer.svelte';

	import Settings from './components/settings/Settings.svelte';

	import ErrorPage from './components/error-page/ErrorPage.svelte';

	import {defaultLocDesc, getLocalizedDescriptions} from "./services/i18n";
	import {layout} from './store';

    const API = APP_CONFIG.API;

	const MAX_DURATION_OF_INACTIVE_NON_DEFAULT_VIEW_IN_MS = 
			APP_CONFIG.MAX_DURATION_OF_INACTIVE_NON_DEFAULT_VIEW_IN_MINUTES * 
			60 * 1000;

	const DEF_SETTINGS = APP_CONFIG.SETTINGS;

	const MAP_STYLE_URLS = APP_CONFIG.MAPBOX.MAP_STYLE_URLS;

	// Regular expressions for `MAIN_UPDATE_INTERVAL` and
	// `STATISTICS_UPDATE_INTERVAL`
	const MAIN_UPDATE_INTERVAL_RE = new RegExp(
			APP_CONFIG.MAIN_UPDATE_INTERVAL
	);
	const STATS_UPDATE_INTERVAL_RE = new RegExp(
			APP_CONFIG.STATS_UPDATE_INTERVAL
	);

	const DATA_UPDATE_RETRY_TIMEOUT = 30 * 1000; // 30 seconds

	// export let showLogos;
	export let isMapboxGLSupport;

	// Settings
	let showSettings = false;

    let layoutStyle = DEF_SETTINGS.LAYOUT_STYLE; 		// 0 = Style A, 1 = Style B
    let clientsFormat = DEF_SETTINGS.CLIENTS_FORMAT; 	// 0 = Text, 1 = Logos
    let mapColorStyle = DEF_SETTINGS.MAP_COLOR_STYLE;   // 0 = Standard, 1 = Lite, 2 = Chromatic
    let mapCoverStyle = DEF_SETTINGS.MAP_COVER_STYLE;   // 0 = White, 1 = Light, 2 = Dark
	let localeId = DEF_SETTINGS.LOCALE_ID;				// de | fr | it

	let isInitSettingsDone = false;

	$: isAltLayout = layoutStyle === 1;
	$: showLogos = clientsFormat !== 0;

	let dateTime;

	let defaultMapStyleURL = MAP_STYLE_URLS[0][0];
	let isMap = false;

	//
	// Campaign Statistics
	//

	// Defaults
	let statistics = {
		teams: 0,
		employee_days: 0,
		donors: 0,
		donors_approved: 0,
		amount_average: 0,
		amount_total: 0,
		top_donors: [],
		top_highest: [],
		top_totals: [],
	};

	//  New cleaned up locations and client data
	let locations = [];
	let nofLocations = 0;
	let locationsChecksum = '';

	let clients = [];
	let nofClients = 0;
	let clientsChecksum = '';

	let activeClientCode = ''; // Empty string for `All Clients/Clients`

	let errorMessages = [];

	let isReadyForCampaignDataUpdates = false;

	let showExternalPage = false;
	let externalPageURL = '';

    // Timeout Id's
    let nonDefaultViewTimeoutId;
	let retryMainDataUpdateTimeoutId;
	let retryStatisticsUpdateTimeoutId;

    // Localized descriptions
    let locDesc = defaultLocDesc;

	$: isError = errorMessages.length > 0;

	// Temp
	$: {

		// console.log('typeof mapboxgl: %s', typeof mapboxgl);
		// console.log('isMapboxGLSupport: %s', isMapboxGLSupport);
		// console.log('isMap: %s', isMap);
		// console.log('isReadyForCampaignDataUpdates: %s', isReadyForCampaignDataUpdates);
		// console.log('errorMessages: %o', errorMessages);

		// console.log('layoutStyle: %s', layoutStyle);
        // console.log('clientsFormat: %s', clientsFormat);
		// console.log('mapColorStyle: %s', mapColorStyle);
		// console.log('mapCoverStyle: %s', mapCoverStyle);
        // console.log('FINAL layoutInfo: %o', layoutInfo);
		// console.log('layout: %o', $layout);

		// console.log('localeId: %s', localeId);
	}

	beforeUpdate(async () => {

		// Override default settings if appropriate

		if (isInitSettingsDone) {
			return;
		}

		if (APP_CONFIG.PERMIT_SETTINGS_ACCESS) {

			if (localStorage.getItem('layoutStyle')) {
				layoutStyle = parseInt(localStorage.getItem('layoutStyle'));
			}

			if (localStorage.getItem('clientsFormat')) {
				clientsFormat = parseInt(localStorage.getItem('clientsFormat'));
			}

			if (localStorage.getItem('mapColorStyle')) {
				mapColorStyle = parseInt(localStorage.getItem('mapColorStyle'));
			}

			if (localStorage.getItem('mapCoverStyle')) {
				mapCoverStyle = parseInt(localStorage.getItem('mapCoverStyle'));
			}

			if (localStorage.getItem('localeId')) {
				localeId = localStorage.getItem('localeId');
			}
		}

		// Set default map style URL
		defaultMapStyleURL = MAP_STYLE_URLS[mapColorStyle][mapCoverStyle];

        handleLayoutSize();

		isInitSettingsDone = true;
	})

	onMount(async () => {

		window.addEventListener('error', handleError);
		window.addEventListener('resize', debounce(handleLayoutSize, 50));
		window.addEventListener('pointerdown', setNoneDefaultViewTimeout);

        // handleLayoutSize();

		// Get locale descriptions
		locDesc = await getLocalizedDescriptions(localeId);

		// Get all campaign data
		const isInitialRequest = true;
		await handleMainDataUpdate(isInitialRequest);
		await handleStatisticsDataUpdate(isInitialRequest);

		isReadyForCampaignDataUpdates = true;
	});

	onDestroy(() => {

		window.removeEventListener('error', handleError);
        window.removeEventListener('resize', debounce);
		window.removeEventListener('pointerdown', setNoneDefaultViewTimeout);

		if (typeof nonDefaultViewTimeoutId === 'number') {
			clearTimeout(nonDefaultViewTimeoutId);
		}

		if (typeof retryMainDataUpdateTimeoutId === 'number') {
			clearTimeout(retryMainDataUpdateTimeoutId);
		}

		if (typeof retryStatisticsUpdateTimeoutId === 'number') {
			clearTimeout(retryStatisticsUpdateTimeoutId);
		}
	});

	// handleClockTick runs a few milliseconds after every full minute
	function handleClockTick(cEvt) {

		// dateTime is used for Data/Time display
		dateTime = cEvt.detail;

		// console.log('dateTime: %o', dateTime);

		// If no initial data ignore campaign data updates
		if (!isReadyForCampaignDataUpdates) {
			return;
		}

		// Fire campaign data updates if appropriate.
		// The update intervals are defined by regular expression literals in
		// index.html (`MAIN_UPDATE_INTERVAL` and `STATISTICS_UPDATE_INTERVAL`).
		// They are tested every minute against an `hh:mm` (hours:minutes)
		// string between 00:00 and 23:59.

		// Get time label in the format 00:00-23:59
		const timeLabel = getTimeLabel(dateTime.hours, dateTime.minutes);

		// console.log('timeLabel: %s', timeLabel);

		const isInitialRequest = false;

		// If regex matches main data update interval
		if (MAIN_UPDATE_INTERVAL_RE.test(timeLabel)) {
			handleMainDataUpdate(isInitialRequest);
		}

		// If regex matches statistics data update interval
		if (STATS_UPDATE_INTERVAL_RE.test(timeLabel)) {
			handleStatisticsDataUpdate(isInitialRequest);
		}
    }

	async function handleMainDataUpdate(isInitialRequest,  isRetry = false) {

		// isRetry is only set when calling this function recursively, it
		// serves as a flag indicating that we retry a failed update.

		// console.log('handleMainDataUpdate()');

		// Get/update locations and clients
		try {

			const dataPkg = await getLocationsAndClientsDataPkg(
					isInitialRequest);

			// Ignore update in case of a request failure after initialization
			// which is indicated by null values in `dataPkg`. Upon
			// request failure retry the update/request one more time, if it
			// fails again we wait for the next regular update.
			if (dataPkg.locations === null || dataPkg.clients === null) {

				let errorMsg = getTimeLabel(dateTime.hours, dateTime.minutes);
				errorMsg += ' MAIN DATA UPDATE IGNORED DUE REQUEST FAILURE';

				console.error(errorMsg);

				// If we didn't retry yet
				if (!isRetry) {

					// console.log('RETRYING MAIN UPDATE IN %s MS',
					// 		DATA_UPDATE_RETRY_TIMEOUT);

					setTimeout(() => {
						retryMainDataUpdateTimeoutId =
								handleMainDataUpdate(isInitialRequest, true);
					}, DATA_UPDATE_RETRY_TIMEOUT);
				}

				return;
			}

			// console.log('MAIN DATA UPDATE REQUEST OK - HANDLING MAIN DATA');

			// console.log('dataPkg: %o', dataPkg);

			// if there are no changes in the checksums there is no update
			// required.
			if (locationsChecksum === dataPkg.locationsChecksum &&
					clientsChecksum === dataPkg.clientsChecksum) {
				// console.log('NO CHECKSUM CHANGES - NOTHING TO UPDATE');
				return;
			}

			// if checksum changes

			// console.log('CHECKSUMS CHANGED - PERFORMING UPDATE');

			// Make sure that any activeClientCode exists in the new data,
			// if not reset it to empty string.
			if (activeClientCode !== '') {
				// TODO
				// For now just do a reset
				activeClientCode = '';
			}

			locations = dataPkg.locations;
			nofLocations = dataPkg.nofLocations;
			locationsChecksum = dataPkg.locationsChecksum;
			clients = dataPkg.clients;
			nofClients = dataPkg.nofClients;
			clientsChecksum = dataPkg.clientsChecksum;

			// console.log('locations: %o', locations);

			// Set MapMarkers
			setMapMarkers({
				locations,
				nofLocations,
				clients,
				activeClientCode
			});
		}
		catch (ex) {

			const msg = 'Error in handleMainDataUpdate() - ' + ex;

			errorMessages = [...errorMessages, msg];
		}
	}

	async function handleStatisticsDataUpdate(isInitialRequest, isRetry = false) {

		// isRetry is only set when calling this function recursively, it
		// serves as a flag indicating that we retry a failed update.

		// console.log('handleStatisticsDataUpdate()');

		const statisticsData = await getStatisticsData(isInitialRequest);

		// console.log('statisticsData: %o', statisticsData);

		// Ignore update in case of a request failure after initialization
		// which is indicated by a null value of `statisticsData`. Upon
		// request failure retry the update/request one more time, if it
		// fails again we wait for the next regular update.
		if (statisticsData === null) {

			let errorMsg = getTimeLabel(dateTime.hours, dateTime.minutes);
			errorMsg += ' STATISTICS UPDATE IGNORED DUE REQUEST FAILURE';

			console.error(errorMsg);

			// If we didn't retry yet
			if (!isRetry) {

				// console.log('RETRYING STATISTICS UPDATE IN %s MS',
				// 		DATA_UPDATE_RETRY_TIMEOUT);

				setTimeout(() => {
					retryStatisticsUpdateTimeoutId =
							handleStatisticsDataUpdate(isInitialRequest, true);
				}, DATA_UPDATE_RETRY_TIMEOUT);
			}

			return;
		}

		// console.log('STATISTICS HAS BEEN UPDATED');

		statistics = statisticsData;
	}

	function handleClientCodeChange(cEvt) {

		// console.log('handleClientCodeChange() %o', cEvt);
		activeClientCode = cEvt.detail.nextClientCode;

        // Highlight map markers
        highlightMapMarkers(activeClientCode);

		// Reset zoom if user clicks on "All Clients"
		if (activeClientCode === '') {
			zoomCountryBounds();
			// setIsDefaultMapView();
		}
    }

    async function handleSettingsChange(cEvt) {

		const {settingsName, value} = cEvt.detail;

		// Save settings group value to local storage
		// console.log(`Save to localStorage: ${settingsName}=${value}`);
		localStorage.setItem(settingsName, value);

		switch(settingsName) {

			case 'layoutStyle':

				if ($layout.showStatistics) {
					handleLayoutSize();
				}
				break;

			case 'mapColorStyle':
			case 'mapCoverStyle':

				const mapStyleURL = MAP_STYLE_URLS[mapColorStyle][mapCoverStyle];
				setMapStyleURL(mapStyleURL);

				// Layout (map container size) changes may require a map.resize()
				resizeMap();
				break;

			case 'localeId':

				// Update locale descriptions
				locDesc = await getLocalizedDescriptions(localeId);

				// Set map language
				setMapLanguage(localeId);

				break;

			default:

				// Other settings require no specific action
		}
	}

	function handleLayoutSize() {

		// console.log('handleLayoutSize()');
        const layoutInfo = getLayoutInfo(isAltLayout);

        layout.set(layoutInfo);

		// Layout (map container size) changes may require a map.resize()
		resizeMap();
    }

    function setNoneDefaultViewTimeout() {

		// For simplicity we assume that we are not in app default view
		// upon any user interaction, respectively as soon as this function
		// is invoked via a single window `pointerdown` event.

		// console.log('#### NOTICED USER INTERACTION');

		// Cancel previous timout
		clearTimeout(nonDefaultViewTimeoutId);

		// Set new timeout
		nonDefaultViewTimeoutId = setTimeout(() => {

			//
			// Reset app to default view
			//

			// console.log('#### RESET APP TO DEFAULT VIEW ...');

			// If no external page
			if (!showExternalPage) {

				if (activeClientCode !== '') {

					// activeClientCode change will automatically close
					// any open map popup

					activeClientCode = '';
					highlightMapMarkers(activeClientCode);
				}
				else {

					closeAnyOpenMapPopup();
				}

				zoomCountryBounds();

				if (showSettings) {
				    showSettings = false;
                }
			}
			else {
				// If external page
				location.reload();
			}

		}, MAX_DURATION_OF_INACTIVE_NON_DEFAULT_VIEW_IN_MS);
	}

    function handleExternalLinkClick(cEvt) {

		externalPageURL = cEvt.detail.URL;
		showExternalPage = true;
	}

	function handleError(cEvt) {

		errorMessages = [...errorMessages, cEvt.detail.errorMessage];
	}

</script>

<style>

	.main-layout {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: space-between;
		/*align-content: space-between;*/
		width: 100%;
		/*border: 2px solid #0033ff;*/
		/*background-color: yellow;*/
	}

	/*.main-layout > div {*/
	/*	border: 1px solid #ff00cc;*/
	/*}*/

	.primary-panel-layout {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.secondary-panel-layout {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		/*border: 1px solid red;*/
	}

	.secondary-panel-layout--main {
		flex-grow: 1;
		overflow: auto;
		/*border: 1px solid red;*/
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.secondary-panel-layout--main::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.secondary-panel-layout--main {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	iframe {
		border: none;
		overflow: auto;
		border-radius: 8px;
	}

</style>

{#if !isError}

	<Clock
		on:clock_tick={handleClockTick}
	/>

	<Header
		{isAltLayout}
		{dateTime}
		{locDesc}
	/>

	<div
		class="main-layout"
		style="padding-left:{$layout.leftRightMargin}px;padding-right:{$layout.leftRightMargin}px"
	>

		<div class="main-layout--primary-panel">

			<PanelFrame showFrame={true}>

				<div
						class="primary-panel-layout"
						style="width:{$layout.primaryPanelWidth}px"
				>

					{#if !showExternalPage}

						{#if $layout.showClients}

							<Clients
									{clients}
									{nofClients}
									{activeClientCode}
									{showLogos}
									{nofLocations}
									{locDesc}
									on:client_code_change={handleClientCodeChange}
							/>

						{/if}

						<Map
								{isMapboxGLSupport}
								mapStyleUrl={defaultMapStyleURL}
								{localeId}
								{locDesc}
								on:init_map_completed={() => isMap = true}
						/>

					{:else}
						<!-- #LM -->
						<iframe
								title="Externe Seite"
								src={externalPageURL}
								width={$layout.primaryPanelWidth}
								height={$layout.mainHeight}
								allowfullscreen="false"
								allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
								sandbox="allow-same-origin allow-scripts allow-forms"
						>
						</iframe>

					{/if}

				</div>

			</PanelFrame>

		</div>

		{#if $layout.showStatistics}

			<div class="main-layout--secondary-panel">

				<PanelFrame showFrame={!isAltLayout}>

					<div
							class="secondary-panel-layout"
							style="width:{$layout.statisticsWidth}px;height:{$layout.mainHeight}px"
					>

						{#if !isAltLayout}

							<StatisticsHeader />

						{/if}

						<div class="secondary-panel-layout--main">

							<Statistics
									{statistics}
									{isAltLayout}
									{locDesc}
							/>

						</div>

						<div class="secondary-panel-layout--footer">

							<Footer
									{isAltLayout}
									{localeId}
									{locDesc}
									on:external_link_click={handleExternalLinkClick}
							/>

						</div>

					</div>

				</PanelFrame>

			</div>

		{/if}

	</div>

	{#if APP_CONFIG.PERMIT_SETTINGS_ACCESS}

		<Settings
			{showSettings}
			bind:layoutStyle={layoutStyle}
			bind:clientsFormat={clientsFormat}
			bind:mapColorStyle={mapColorStyle}
			bind:mapCoverStyle={mapCoverStyle}
			bind:localeId={localeId}
			{locDesc}
			on:open_settings={() => showSettings = true}
			on:close_settings={() => showSettings = false}
			on:settings_change={handleSettingsChange}
		/>

	{/if}

{:else}

	<ErrorPage {errorMessages} />

{/if}
