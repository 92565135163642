
function getMapPinElements(color, opacity) {

    const divEl = document.createElement('div');

    divEl.style.opacity = opacity;
    divEl.style.cursor = 'pointer';

    const NS = "http://www.w3.org/2000/svg";

    // Create SVG Icon
    // create the svg element
    const svgEl = document.createElementNS(NS, "svg");

    // set width and height
    svgEl.setAttribute("width", "27");
    svgEl.setAttribute("height", "36");
    svgEl.setAttribute("viewBox", "0 0 27 36");

    // Create the g element
    const gEl = document.createElementNS(NS, "g");
    gEl.setAttribute("fill", color);

    // Create path1
    const path1 = document.createElementNS(NS, "path");
    path1.setAttribute("d", "M 13.50002670288086 35.28125 C 12.54763889312744 34.28699493408203 9.828853607177734 31.38039016723633 7.140561103820801 27.86996078491211 C 2.796270847320557 22.19709968566895 0.5000009536743164 17.53926086425781 0.5000009536743164 14.39999961853027 C 0.5000009536743164 10.68113040924072 1.856220960617065 7.18641996383667 4.318830966949463 4.559629917144775 C 6.773100852966309 1.941740036010742 10.03371047973633 0.5 13.50000095367432 0.5 C 16.96630096435547 0.5 20.22690010070801 1.941740036010742 22.68118095397949 4.559629917144775 C 25.1437816619873 7.186409950256348 26.5 10.68111991882324 26.5 14.39999961853027 C 26.5 17.54088020324707 24.20153045654297 22.20159912109375 19.85307121276855 27.87828063964844 C 17.16537857055664 31.38692092895508 14.45138359069824 34.28817749023438 13.50002670288086 35.28125 Z");
    path1.setAttribute("stroke", "none");

    // Create path2
    const path2 = document.createElementNS(NS, "path");
    path2.setAttribute("d", "M 13.50000095367432 1 C 10.17339134216309 1 7.042341232299805 2.3856201171875 4.683601379394531 4.901599884033203 C 2.308191299438477 7.435369491577148 1.000001907348633 10.80862998962402 1.000001907348633 14.39999961853027 C 1.000001907348633 17.42679977416992 3.258440017700195 21.97663879394531 7.531171798706055 27.55767059326172 C 9.921604156494141 30.68003845214844 12.33811378479004 33.32375717163086 13.50008773803711 34.55550384521484 C 14.65937995910645 33.32680511474609 17.0675163269043 30.69247055053711 19.45615005493164 27.5742301940918 C 23.7371711730957 21.98556900024414 26 17.43000030517578 26 14.39999961853027 C 26 10.80862998962402 24.69181060791016 7.435369491577148 22.31641006469727 4.901599884033203 C 19.95767211914063 2.3856201171875 16.82661056518555 1 13.50000095367432 1 M 13.50000095367432 0 C 20.95585060119629 0 27 6.447099685668945 27 14.39999961853027 C 27 22.3528995513916 13.50000095367432 36 13.50000095367432 36 C 13.50000095367432 36 1.9073486328125e-06 22.3528995513916 1.9073486328125e-06 14.39999961853027 C 1.9073486328125e-06 6.447099685668945 6.044160842895508 0 13.50000095367432 0 Z");
    path2.setAttribute("stroke", "none");
    path2.setAttribute("fill", "#030303");

    gEl.appendChild(path1);
    gEl.appendChild(path2);
    svgEl.appendChild(gEl);
    divEl.appendChild(svgEl);

    // Simple circle, simply style the div element
    // This was used for d2d campaign type

    // divEl.style.width = '24px';
    // divEl.style.height = '24px';
    // divEl.style.backgroundColor = color;
    // divEl.style.borderColor = 'Black';
    // divEl.style.borderWidth = '1px';
    // divEl.style.borderStyle = 'solid';
    // divEl.style.borderRadius = '12px';

    return {
        divEl,
        gEl
    };
}

export {
    getMapPinElements
};
