
const MAP_SETTINGS = {

    // Country bounds for Switzerland
    COUNTRY_BOUNDS: [
        [5.9559111595, 45.8179931641],  // southwest coordinates [lng, lat]
        [10.4920501709, 47.808380],     // northeast coordinates [lng, lat]
    ],

    COUNTRY_BOUNDS_PADDING: {top: 20, bottom:25, left: 24, right: 24},

    // Max bounds are set to about the double size of Switzerland
    MAX_BOUNDS: [
        [1.5, 43.8],    // southwest coordinates [lng, lat]
        [15, 49.8]      // northeast coordinates [lng, lat]
    ],

    MAX_ZOOM: 19,
};

const NUM_FORMATS = {
    INTEGER: 'INTEGER',
    FLOAT_1_FRACTION_DIGIT: 'FLOAT_1_FRACTION_DIGIT',
    FLOAT_2_FRACTION_DIGITS: 'FLOAT_2_FRACTION_DIGITS',
};

export {
    MAP_SETTINGS,
    NUM_FORMATS
};
