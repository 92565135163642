<script>

    import {NUM_FORMATS} from "../../constants";

    import StatisticsItem from './StatisticsItem.svelte';
    import TopPerformers from './TopPerformers.svelte';

    import {layout} from "../../store";

    const DEFAULT_ITEM =  {
        name: '—',
        value: 0
    };

    export let statistics;
    export let isAltLayout;
    export let locDesc;

    $: nofTeams = statistics.teams;
    $: nofEmployeeDays = statistics.employee_days;
    $: nofDonors = statistics.donors;
    $: nofDonorsApproved = statistics.donors_approved;

    $: aveAmount = statistics.amount_average;
    $: totalAmount = statistics.amount_total;

    $: topDonors = getFinalItems(statistics.top_donors, 'approved');
    $: topHighest = getFinalItems(statistics.top_highest, 'max_amount')
    $: topTotals = getFinalItems(statistics.top_totals, 'total');

    $: {
        // console.log('layout: %o', $layout);
        // console.log('statistics.donors: %s', statistics.donors);
        // console.log('nofDonors: %s', nofDonors);
        // console.log('nofDonorsApproved: %s', nofDonorsApproved);
    }

    function getDonorsApprovedPercent(nofDonors, nofDonorsApproved) {

        if (nofDonors === 0) {
            return 0;
        }

        return (nofDonorsApproved / nofDonors) * 100;
    }

    function getDonorsProEmployeeDays(nofDonors, nofEmployeeDays) {

        if (nofDonors === 0 || nofEmployeeDays === 0) {
            return 0;
        }

        return nofDonors / nofEmployeeDays;
    }

    function getFinalItems(srcItems, valuePropName) {

        const iMaxOfSrcItems = srcItems.length - 1;
        const items = [];

        for (let i = 0; i < 3; i++) {

            if (i <= iMaxOfSrcItems) {

                const srcItem = srcItems[i];

                items[i] = {
                    name: srcItem.name,
                    value: srcItem[valuePropName]
                }
            }
            else {

                items[i] = DEFAULT_ITEM;
            }
        }

        return items;
    }

</script>

<style>

  .stats-box {
    box-sizing: border-box;
    padding: 0 24px;

    /*border: 1px solid goldenrod;*/
  }

  /* Padding in alternative layout! */
  .stats-box--compact {
    padding: 0 6px 0 4px;
  }

  .primary-stats-container {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.4;
  }

  .primary-stats-box {
    padding: 8px 0;
  }

  .primary-stats-box .title {
    margin-bottom: 9px;
  }

  /* Currency characters */
  .primary-stats-box span {
    font-weight: 300;
  }

  .top-performers-container {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  /* Top performers title */
  .top-performers-title {
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    margin: 28px 0 12px 0;
  }

  .top-performers-box {
    padding: 8px 0;
  }

  .primary-stats-box,
  .top-performers-box {
    border-bottom: 1px dotted #35A4A7;
  }

</style>


<div
    class={!isAltLayout ? 'stats-box' : 'stats-box stats-box--compact'}
>

    <div
            class="primary-stats-container"
            style="font-size:{$layout.primaryStatsFontSize}rem;line-height:{$layout.primaryStatsLineHeight}"
    >

        <div class="primary-stats-box">

            <StatisticsItem
                    label={locDesc.teams}
                    value={nofTeams}
                    format={NUM_FORMATS.INTEGER}
            />

        </div>

        <div class="primary-stats-box">

            <StatisticsItem
                    label={locDesc.employee_days}
                    value={nofEmployeeDays}
                    format={NUM_FORMATS.INTEGER}
            />

        </div>


        <div class="primary-stats-box">

            <StatisticsItem
                    label={locDesc.donors}
                    value={nofDonors}
                    format={NUM_FORMATS.INTEGER}
            />

            <StatisticsItem
                label={locDesc.donors_approved}
                value={getDonorsApprovedPercent(nofDonors, nofDonorsApproved)}
                format={NUM_FORMATS.INTEGER}
                unitSymbol="%"
            />

            <StatisticsItem
                label={locDesc.donors + ' / ' + locDesc.employee_days}
                value={getDonorsProEmployeeDays(nofDonors, nofEmployeeDays)}
                format={NUM_FORMATS.FLOAT_1_FRACTION_DIGIT}
            />

        </div>

        <div class="primary-stats-box">

            <div class="title">
                {locDesc.donors_amount} <span>({locDesc.currency_symbol})</span>
            </div>

            <StatisticsItem
                label={locDesc.average}
                value={aveAmount}
                format={NUM_FORMATS.INTEGER}
            />

            <StatisticsItem
                label={locDesc.total}
                value={totalAmount}
                format={NUM_FORMATS.INTEGER}
            />

        </div>

    </div>

    <div
            class="top-performers-container"
            style="font-size:{$layout.secondaryStatsFontSize}rem;line-height:{$layout.secondaryStatsLineHeight}"
    >

        <div class="top-performers-title">
            {locDesc.top_performers.toUpperCase()}
        </div>

        <div class="top-performers-box">

            <TopPerformers
                label={locDesc.top_donors_approved}
                items={topDonors}
                format={NUM_FORMATS.INTEGER}
                {locDesc}
            />

        </div>

        <div class="top-performers-box">

            <TopPerformers
                    label={locDesc.top_totals}
                    isCurrency={true}
                    items={topTotals}
                    format={NUM_FORMATS.INTEGER}
                    {locDesc}
            />

        </div>

        <div class="top-performers-box">

            <TopPerformers
                    label={locDesc.top_highest}
                    isCurrency={true}
                    items={topHighest}
                    format={NUM_FORMATS.INTEGER}
                    {locDesc}
            />

        </div>

    </div>

</div>
