<script>

    import {createEventDispatcher, onMount, beforeUpdate} from 'svelte';

    import LogoItem from './LogoItem.svelte';

    import {layout} from "../../../store";

    const dispatch = createEventDispatcher();

    export let clients;
    export let nofClients;
    export let activeClientCode;
    export let locDesc;

    let logoWidth = 0;
    let logoHeight = 0;

    $: clientPairs = getClientPairs(clients);

    // $: {
    //     console.log('clientPairs: %o', clientPairs);
    // }

    beforeUpdate(() => {

        logoWidth = Math.round(($layout.clientsWidth * 0.86) / 2);
        logoHeight = Math.round(logoWidth * 0.53);
    });

    function getClientPairs(clients) {

        const pairs = [];

        for (let i = 0; i < nofClients; i++) {

            if (i % 2 === 0) {

                const pair = [];
                pair[0] = clients[i];

                if (i + 1 < nofClients) {
                    pair[1] = clients[i + 1];
                }

                pairs.push(pair);
            }
        }

        return pairs;
    }

    /*
    function handleClientCodeChange(cEvt) {

        // This intercepts the dispatched `client_code_change` so that we
        // can handle any necessary zoom action.

        const nextClientCode = cEvt.detail.nextClientCode;

        dispatch('client_code_change', {
            nextClientCode: nextClientCode
        });
    }
     */

    function handleListSurfaceClick(evt) {

        // Resets to all clients if client is active
        if (activeClientCode !== '') {

            dispatch('client_code_change', {
                nextClientCode: ''
            });
        }
    }

</script>

<style>

  .logos-list {
    box-sizing: border-box;
    padding-top: 6px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .logo-pair {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    background-color: transparent;
  }

  .clients-reset-info {
    color: #858585;
    font-size: 1.6rem;
    font-weight: 300;
    text-align: left;
    user-select: none;
    padding: 22px;
    /*border: 1px solid red;*/
  }


</style>

<div
    class="logos-list"
    on:click={handleListSurfaceClick}
>

    <!--
    {#each clients as {clientCode, color, name, logoURL, count}, i (clientCode)}
    -->

    {#each clientPairs as clientPair, i}

            <div class="logo-pair">

                {#each clientPair as {clientCode, color, name, logoURL} (clientCode)}

                    <LogoItem
                            {clientCode}
                            {color}
                            {logoWidth}
                            {logoHeight}
                            {name}
                            {logoURL}
                            {locDesc}
                            on:client_code_change
                    />

                {/each}

                {#if clientPair.length === 1}

                    <LogoItem
                            clientCode=""
                            color=""
                            {logoWidth}
                            {logoHeight}
                            name=""
                            logoURL=""
                            {locDesc}
                            isPlaceholder={true}
                    />

                {/if}

            </div>

    {/each}

    {#if nofClients > 1 && activeClientCode !== ''}

        <div
                class="clients-reset-info"
                style="font-size:{$layout.bodyFontSize}rem;padding:{$layout.bodyFontSize * 1.2}rem"
        >

            {locDesc.clients_reset_info}

        </div>

    {/if}

</div>
