
function getAddressBox(params) {

    const {
        city,
        location,
        streetAddress,
        zip
    } = params;

    let s = '';

    if (location !== '') {
        s += location;
    }

    if (streetAddress !== '' &&
        streetAddress.toLowerCase() !== location.toLowerCase()) {

        if (s !== '') {
            s += '<br />'
        }

        s += streetAddress;
    }

    if (zip !== '' || city !== '') {

        if (s !== '') {
            s += '<br />'
        }

        if (zip !== '') {
            s += zip + ' ';
        }

        s += city;
    }

    if (s === '') {
        '';
    }

    return `<div class="address">${s}</div>`;
}

function getPopupHTML(params) {

    const {clientName, teamName} = params;

    return `
        <div class="map-popup">
        <h4>${clientName}</h4>
        <div class="team">${teamName}</div>
        ${getAddressBox(params)}
        </div>
    `;
}

function getMapPopup(params) {

    const popupHTML = getPopupHTML(params);

    return new mapboxgl.Popup({
        offset: 12,
        maxWidth: '280px'
    }).setHTML(popupHTML);
}

export {
    getMapPopup
};
