<script>

    import {getFormattedNum} from '../../services/util';

    export let label;
    // export let prefixValue = -1;
    export let value;
    export let format;
    export let unitSymbol = '';

</script>

<style>

  .stats-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;
  }

  .stats-item > div {
    /*border: 1px solid blueviolet;*/
      white-space: nowrap;
  }

  .stats-item > div:last-child {
    font-family: "Roboto Mono", monospace;
  }

  /*.prefix-value {*/
  /*    display: inline-block;*/
  /*    !*font-weight: 300;*!*/
  /*    padding-right: 2px;*/
  /*}*/

  /* prefix-value braces */
  /*.prefix-value span:first-child,*/
  /*.prefix-value span:last-child{*/
  /*    font-weight: 300;*/
  /*    opacity: 0.7;*/
  /*}*/

  /* prefix-value percent symbol */
  /*.prefix-value span.percent {*/
  /*    padding-left: 1px;*/
  /*}*/

  .unit-symbol {
      float: right;
      padding-left: 1px;
  }

</style>

<div class="stats-item">

    <div>
        {label}
    </div>

    <div>

        <!-- prefixValue is currently only used for the percent_value of
        donors_approved. Hence we ignore formatting and the percent symbol
        option -->
        <!--
        {#if prefixValue > -1}
            <div class="prefix-value">
                <span>(</span>{prefixValue}<span class="percent">%</span><span>)</span>
            </div>
        {/if}
        -->

        {getFormattedNum(value, format)}

        {#if unitSymbol !== ''}
            <span class="unit-symbol">{unitSymbol}</span>
        {/if}

    </div>

</div>
