

// Width and height sizes are in `pixel` unit
// Font sizes are in `rem` unit

const FLUID_WIDTH_SIZES = {
    MIN: 640,
    MAX: 1920
};

const FLUID_WIDTH_DELTA = FLUID_WIDTH_SIZES.MAX - FLUID_WIDTH_SIZES.MIN;

// Everything below `FLUID_SIZE_BREAKPOINTS` will be considered a mobile 
// device.
const FLUID_SIZE_BREAKPOINTS = {
    LANDSCAPE: {
        WIDTH: FLUID_WIDTH_SIZES.MIN,
        HEIGHT: 540
    },
    PORTRAIT: {
        WIDTH: FLUID_WIDTH_SIZES.MIN,
        HEIGHT: 700
    }
};

// `MOBILE` values are used on mobile devices
// The values between `MIN` and `MAX` are fluid depending on tablet and desktop
// size.

const DEFAULT_SIZES = {
    headerHeight: {IS_ROUND: true, MOBILE: 30, MIN: 40, MAX: 75},
    logoHeight: {IS_ROUND: true, MOBILE: 15, MIN: 19, MAX: 29},
    clientsWidth: {IS_ROUND: true, MOBILE: 248, MIN: 248, MAX: 320},

    // Left/right body margin
    leftRightMargin: {IS_ROUND: true, MOBILE: 12, MIN: 18, MAX: 24},


    bodyFontSize: {IS_ROUND: false, MOBILE: 1.4, MIN: 1.5, MAX: 1.6},

    dateTimeFontSize: {IS_ROUND: false, MOBILE: 1.5, MIN: 1.8, MAX: 2.7},
    // dateTimeFontSize if alternative layout
    altDateTimeFontSize: {IS_ROUND: false, MOBILE: 1.5, MIN: 2.0, MAX: 2.8},

    // Liveticker header
    frameTitleFontSize: {IS_ROUND: false, MOBILE: 1.6, MIN: 2.8, MAX: 3.0},

    primaryStatsFontSize: {IS_ROUND: false, MOBILE: 1.5, MIN: 1.6, MAX: 1.8},
    primaryStatsLineHeight: {IS_ROUND: false, MOBILE: 1.0, MIN: 1.2, MAX: 1.4},
    secondaryStatsFontSize: {IS_ROUND: false, MOBILE: 1.4, MIN: 1.4, MAX: 1.6},
    secondaryStatsLineHeight: {IS_ROUND: false, MOBILE: 1.0, MIN: 1.1, MAX: 1.5}
};

const DEFAULT_STATISTICS_WIDTHS = {
    // stats with liveticker title and frame
    REGULAR: {IS_ROUND: true, MOBILE: 0, MIN: 264, MAX: 320},
    // stats without liveticker title and frame
    ALTERNATIVE: {IS_ROUND: true, MOBILE: 0, MIN: 224, MAX: 280}
};

function getIsMobileDevice(innerWidth, innerHeight, isLandscape) {

    const breakpoints = isLandscape ? FLUID_SIZE_BREAKPOINTS.LANDSCAPE :
        FLUID_SIZE_BREAKPOINTS.PORTRAIT;

    return innerWidth < breakpoints.WIDTH || innerHeight < breakpoints.HEIGHT;
}

function getDefaultLayoutSizes(sizePropName) {

    const keys = Object.keys(DEFAULT_SIZES);

    const defaultSizes = {};

    for (const key of keys) {

        defaultSizes[key] = DEFAULT_SIZES[key][sizePropName]
    }

    return defaultSizes;
}

function getFluidSize(item, actualWidthDelta) {

    const minSize = item.MIN;
    const maxSize = item.MAX;
    const isRound = item.IS_ROUND;

    const calcSize = minSize + (((maxSize - minSize) / FLUID_WIDTH_DELTA) *
        actualWidthDelta);

    if (!isRound) {
        return Number.parseFloat(calcSize.toFixed(1));
    }

    return Math.round(calcSize);
}

function calcRemainingSizes(innerWidth, innerHeight, sizesItem, isAltLayout) {

    // Calculates sizes for
    // primaryPanelWidth
    // mapWidth
    // mainHeight
    // bottomMargin

    const {
        showClients,
        showStatistics,
        headerHeight,
        clientsWidth,
        statisticsWidth,
        leftRightMargin,
    } = sizesItem;

    const panelBorderWidths = 2;

    //
    // primaryPanelWidth and mapWidth
    //

    let middleMarginAndStatsWidth = 0;

    if (showStatistics) {

        // middleMargin is the margin between clients and statistics, set it to
        // 110% of leftRightMargin
        const middleMargin = Math.ceil(leftRightMargin * 1.1);

        // console.log('middleMargin: %s', middleMargin);

        middleMarginAndStatsWidth = middleMargin + statisticsWidth;

        // If statistics panel with borders
        if (!isAltLayout) {
            middleMarginAndStatsWidth += panelBorderWidths;
        }
    }

    // primaryPanelWidth without left/right panel border
    const primaryPanelWidth = innerWidth - (leftRightMargin * 2) -
        panelBorderWidths - middleMarginAndStatsWidth;

    const mapWidth = showClients ? primaryPanelWidth - clientsWidth :
        primaryPanelWidth;

    //
    // mainHeight
    //

    // Set bottom margin to 90% of leftRight margin
    // const verticalBorderWidths = 2;
    const bottomMargin = Math.round(leftRightMargin * 0.9);
    const mainHeight = innerHeight - (headerHeight + panelBorderWidths +
        bottomMargin);

    // console.log('innerWidth: %s', innerWidth);
    // console.log('leftRightMargin: %s', leftRightMargin);
    // console.log('clientsWidth: %s', clientsWidth);
    // console.log('statisticsWidth: %s', statisticsWidth);
    // console.log('mapWidth: %s', mapWidth);

    const finalSizesItem = {
        primaryPanelWidth,
        mapWidth,
        mainHeight,
        ...sizesItem
    };

    // console.log('finalSizesItem: %o', finalSizesItem);
    // console.log('bottomMargin: %s', bottomMargin);

    return finalSizesItem;
}

function getLayoutInfo(isAltLayout) {

    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (!innerWidth || innerWidth === 0) {
        innerWidth = 1280;
    }

    if (!innerHeight || innerHeight === 0) {
        innerHeight = 800;
    }

    const isLandscape = innerWidth > innerHeight;

    const isMobileDevice = getIsMobileDevice(innerWidth, innerHeight,
        isLandscape);
    
    const defaultStatisticsWidth = !isAltLayout ?
        DEFAULT_STATISTICS_WIDTHS.REGULAR : 
        DEFAULT_STATISTICS_WIDTHS.ALTERNATIVE;

    // console.log('innerWidth: %s', innerWidth);
    // console.log('innerHeight: %s', innerHeight);
    // console.log('isLandscape: %s', isLandscape);
    // console.log('isMobileDevice: %s', isMobileDevice);

    let sizesItem;

    if (isMobileDevice) {

        const defaultSizes = getDefaultLayoutSizes('MOBILE');
        const {clientsWidth} = defaultSizes;

        sizesItem = {
            showDateTime: isLandscape && innerWidth >= 420,
            showClients: isLandscape && innerWidth >= clientsWidth + (clientsWidth * 1.5),
            showStatistics: false,
            statisticsWidth: 0,
            ...defaultSizes,
        };
    }
    else if (innerWidth <= FLUID_WIDTH_SIZES.MIN) {

        const defaultSizes = getDefaultLayoutSizes('MIN');
        const {clientsWidth} = defaultSizes;

        sizesItem = {
            showDateTime: isLandscape && innerWidth >= 480,
            showClients: clientsWidth + (clientsWidth * 1.5),
            showStatistics: false,
            statisticsWidth: defaultStatisticsWidth.MIN,
            ...defaultSizes,
        };
    }
    else if (innerWidth >= FLUID_WIDTH_SIZES.MAX) {

        const defaultSizes = getDefaultLayoutSizes('MAX');

        sizesItem = {
            showDateTime: true,
            showClients: true,
            showStatistics: true,
            statisticsWidth: defaultStatisticsWidth.MAX,
            ...defaultSizes,
        };
    }
    else {

        // if fluid between MIN and MAX

        const showDateTime = true;
        const showClients = true;
        const showStatistics = innerWidth >= 990;

        const actualWidthDelta = innerWidth - FLUID_WIDTH_SIZES.MIN;

        const partialSizesItem = {};
        const defaultSizesKeys = Object.keys(DEFAULT_SIZES);

        for (const key of defaultSizesKeys) {

            const defaultSizesItem = DEFAULT_SIZES[key];

            partialSizesItem[key] = getFluidSize(defaultSizesItem,
                actualWidthDelta);
        }

        const statisticsWidth = getFluidSize(defaultStatisticsWidth,
            actualWidthDelta);

        sizesItem = {
            showDateTime,
            showClients,
            showStatistics,
            statisticsWidth,
            ...partialSizesItem
        };
    }

    return calcRemainingSizes(innerWidth, innerHeight, sizesItem, isAltLayout);
}

export {
    getLayoutInfo
};
