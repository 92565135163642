<script>

    import {createEventDispatcher} from 'svelte';

    import SettingsPanel from './SettingsPanel.svelte';

    import {layout} from "../../store";

    const dispatch = createEventDispatcher();

    export let showSettings;
    export let layoutStyle;
    export let clientsFormat;
    export let mapColorStyle;
    export let mapCoverStyle;
    export let localeId;
    export let locDesc;

    function handleOpenSettings() {
        dispatch('open_settings', {});
    }

</script>

<style>

  .settings-container {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    z-index: 102;
    background-color: transparent;
    /*border: 1px solid red;*/
  }

  .show-settings-btn {
    width: 100%;
    min-height: 80px;
    border: none;
    background-color: transparent;
    margin: 0;
  }

  .show-settings-btn span {
    visibility: hidden;
    color: #1d8d8f;
  }

  .show-settings-btn:hover {
    background-color: #e4fdfd;
  }

  .show-settings-btn:hover span {
    visibility: visible;
  }

</style>

<div class="settings-container">

    {#if !showSettings}

        <button
            type="button"
            class="show-settings-btn"
            style="min-height:{$layout.headerHeight}px"
            on:click={handleOpenSettings}
            >

            <span>{locDesc.open_settings}</span>

        </button>

    {:else}

        <SettingsPanel
            bind:layoutStyle={layoutStyle}
            bind:clientsFormat={clientsFormat}
            bind:mapColorStyle={mapColorStyle}
            bind:mapCoverStyle={mapCoverStyle}
            bind:localeId={localeId}
            on:close_settings
            on:settings_change
        />

    {/if}

</div>
