<script>

    import {createEventDispatcher, onMount, onDestroy} from 'svelte';

    /*
     * This clock dispatches date and time values a few milliseconds after
     * every full minute, it is used as follows:
     * a.) For Date/time display
     * b.) As a clock generator to fetch new campaign data
     */

    const dispatch = createEventDispatcher();

    const DELAY_IN_MILLISECONDS = 5;
    let timeoutId = null;
    
    onMount(() => {

        setClock();
    });
    
    onDestroy(() => {
        
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    });

    function getMillisecondsUpToNextMinute(seconds, milliseconds) {

        return 60000 - ((seconds * 1000) + milliseconds);
    }
    
    function setClock() {

        // console.log('setClock()');
        
        const dateTime = new Date();

        const weekday = dateTime.getDay();
        const day = dateTime.getDate();
        const month = dateTime.getMonth() + 1;
        const year = dateTime.getFullYear();
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const seconds = dateTime.getSeconds();
        const milliseconds = dateTime.getMilliseconds();

        //
        // Run the clock again after a few milliseconds of the next full minute
        //

        const millisecondsUpToNextMinute = getMillisecondsUpToNextMinute(
            seconds, milliseconds);

        const nextRunInMilliseconds = millisecondsUpToNextMinute +
            DELAY_IN_MILLISECONDS;

        timeoutId = setTimeout(() => {
            setClock();
        }, nextRunInMilliseconds);

        //
        // Dispatch date/time
        //

        // console.log('dispatching TimerChange ...');

        dispatch('clock_tick', {
            weekday,
            day,
            month,
            year,
            hours,
            minutes,
        });
    }
    
</script>
