
import {
    httpGet,
    getAdvancedColors,
    getISODate,
    getIsLonLatWithinBounds
} from "./util";

import md5 from 'blueimp-md5';

// TEMP
import {sampleData} from '../../sample-data/f2f';

import {MAP_SETTINGS} from "../constants";

const API = APP_CONFIG.API;

const USE_SAMPLE_DATA = APP_CONFIG.USE_SAMPLE_DATA;

// Temp
// Return 0 to N random locations, used for testing to get a different
// locations checksum.
// function convertToRandomLocations(locations) {
//
//     return locations.filter(item => Math.random() < 0.5);
// }

async function getLocationsData(isInitialRequest) {

    const url = `${API.URL_ORIGIN}${API.LOCATIONS_PATH}`;

    if (USE_SAMPLE_DATA) {
        return sampleData.locations;
        // return convertToRandomLocations(sampleData.locations);
    }

    return await httpGet(url, isInitialRequest);
}

async function getClientsData(isInitialRequest) {

    const isoDate = getISODate();
    const url = `${API.URL_ORIGIN}${API.CLIENTS_PATH}/${isoDate}`;

    if (USE_SAMPLE_DATA) {
        return sampleData.clients;
    }

    return await httpGet(url, isInitialRequest);
}

async function getStatisticsData(isInitialRequest) {

    const isoDate = getISODate();
    const url = `${API.URL_ORIGIN}${API.STATISTICS_PATH}/${isoDate}`;

    if (USE_SAMPLE_DATA) {
        return sampleData.statistics;
    }

    return await httpGet(url, isInitialRequest);
}

async function getLocationsAndClientsDataPkg(isInitialRequest) {

    // Note, `clients` should remain sorted as in the response, so
    // don't sort them here!

    const rawLocations = await getLocationsData(isInitialRequest);
    const rawClients = await getClientsData(isInitialRequest);

    // Return object with null values in case of request failures (after
    // initialization) so that we can ignore the update.
    if (rawLocations === null || rawClients === null) {
        return {
            locations: null,
            nofLocations: 0,
            locationsChecksum: '',
            clients: null,
            nofClients: 0,
            clientsChecksum: ''
        };
    }

    // console.log('rawLocations: %o', rawLocations);
    // console.log('rawClients: %o', rawClients);
    
    const clientDetailsByClientCode = {};

    // Handle unique client org codes as sorted in clients because there are
    // duplicates in the clients response!

    const uniqueClientCodes = new Set();

    for (const item of rawClients) {

        const {code, client, color, logo_file} = item;


        // Get advanced color properties
        const advancedColors = getAdvancedColors(color);

        clientDetailsByClientCode[code] = {
            clientCode: code,
            color: advancedColors.color,
            darkColor: advancedColors.darkColor,
            name: client,
            logoURL: logo_file,
            count: 0
        };

        uniqueClientCodes.add(code);
    }

    // console.log('clientDetailsByClientCode: %o', clientDetailsByClientCode);

    //
    // Collect verified locations
    //

    const COUNTRY_BOUNDS = MAP_SETTINGS.COUNTRY_BOUNDS;
    const locations = [];

    for (const item of rawLocations) {

        const {
            code,
            team,
            location_number,
            location,
            street_address,
            zip,
            city,
            latitude,
            longitude
        } = item;

        // Check if the location coordinates are within the country borders

        const isLatLonWithinCountryBounds = getIsLonLatWithinBounds(longitude,
            latitude, COUNTRY_BOUNDS);

        const clientCode = code;
        const isValidClientCode = clientDetailsByClientCode.hasOwnProperty(
            clientCode);

        // console.log('clientCode: %s', clientCode);
        // console.log('isLatLonWithinCountryBounds: %s', isLatLonWithinCountryBounds);
        // console.log('isValidClientCode: %s', isValidClientCode);

        if (isLatLonWithinCountryBounds && isValidClientCode) {

            locations.push({
                clientCode,
                // name: clientDetails.name,
                teamName: team,
                location,
                streetAddress: street_address,
                zip,
                city,
                // color: clientDetails.color,
                lon: longitude,
                lat: latitude,
            });

            // Set client item counter
            clientDetailsByClientCode[clientCode]['count'] += 1;

        }
        else if (!isLatLonWithinCountryBounds) {

            const clientName = isValidClientCode ?
                clientDetailsByClientCode[code]['name'] :
                `Client with code "${code}" does not exist`;

            const msg =
                'IGNORED LOCATION \n' +
                'Invalid longitude and/or latitude coordinates \n' +
                `client: ${clientName} \n` +
                `location_number: ${location_number}`;

            console.warn(msg);
        }
        else {

            const msg =
                'IGNORED LOCATION ITEM \n' +
                `The client with code: ${clientCode} does not exist`;

            console.warn(msg);
        }
    }

    const nofLocations = locations.length;
    const locationsChecksum = nofLocations > 0 ?
        md5(JSON.stringify(locations)) : '';

    //
    // Collect final clients
    //

    const clients = [];

    for (const clientCode of uniqueClientCodes) {

        const clientDetails = clientDetailsByClientCode[clientCode];

        // DISABLED FOR NOW -- WILL SHOW CLIENTS WITH ZERO LOCATIONS
        // Ignore clients which have zero locations due lat/lon error!
        // if (clientDetails.count > 0) {
        //     clients.push(clientDetails);
        // }
        // else {
        //     const msg =
        //         'IGNORED CLIENT ITEM \n' +
        //         'Client has no associated locations, most likely because ' +
        //         'of one or more omitted locations with wrong ' +
        //         'coordinates. \n' +
        //         `client: ${clientDetails.name}`;
        //
        //     console.warn(msg);
        // }

        clients.push(clientDetails);
    }

    const nofClients = clients.length;
    const clientsChecksum = nofClients > 0 ? md5(JSON.stringify(clients)) : '';

    return {
        locations,
        nofLocations,
        locationsChecksum,
        clients,
        nofClients,
        clientsChecksum
    };
}

export {
    getStatisticsData,
    getLocationsAndClientsDataPkg,
};
