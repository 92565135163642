<script>

    import {afterUpdate, onDestroy} from 'svelte';

    import Logo from './Logo.svelte';
    import DateTime from './DateTime.svelte';

    import {layout} from "../../store";

    export let isAltLayout;
    export let dateTime;
    export let locDesc;

</script>

<style>

  .header-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

</style>


<div
        class="header-layout"
        style="height:{$layout.headerHeight}px"
>

    <div>

        <Logo
                {isAltLayout}
                {locDesc}
        />

    </div>

    <div>

        {#if $layout.showDateTime && dateTime}

            <DateTime
                {isAltLayout}
                weekday={dateTime.weekday}
                day={dateTime.day}
                month={dateTime.month}
                year={dateTime.year}
                hours={dateTime.hours}
                minutes={dateTime.minutes}
                {locDesc}
            />

        {/if}

    </div>

</div>
