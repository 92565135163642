<script>

    export let showFrame;

</script>

<style>

  .panel-frame-border {
    border: 1px solid #209497;
    border-radius: 8px;
    z-index: 200;
  }

  .panel-frame-border--none {
    border-color: transparent;
  }

</style>

<div class={showFrame ? 'panel-frame-border' : 'panel-frame-border panel-frame-border--none'}>
<slot></slot>
</div>
