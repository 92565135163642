<script>

    export let errorMessages;

</script>

<style>

  .layout {
    width: 600px;
    padding: 30px;
  }

  .bottom-msg {
    padding: 40px 0 7px 0;
  }

</style>


<div class="layout">

    <!-- #LM -->
    Sorry, the following error(s) occurred:

    {#each errorMessages as errMsg}

        <div class="error-msg">{errMsg}</div>

    {/each}

    <div class="bottom-msg">
        <!-- #LM -->
        Please try reloading the page and/or contact your system administrator.
    </div>

    <button
        type="button"
        on:click={() => location.reload()}
    >
        <!-- #LM -->
        Reload Page
    </button>

</div>
