
import tinycolor from 'tinycolor2';

import {NUM_FORMATS} from "../constants";

const INTL_NUM_FORMAT_INTEGER = Intl.NumberFormat('ch',
    {
        style: 'decimal',
        maximumFractionDigits: 0
    }
);

const INTL_NUM_FORMAT_FLOAT_1_FRACTION_DIGIT = Intl.NumberFormat('ch',
    {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    }
);

const INTL_NUM_FORMAT_FLOAT_2_FRACTION_DIGITS = Intl.NumberFormat('ch',
    {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
);

function dispatchError(errorMessage) {

    const cEvt = new CustomEvent('error', {
        detail: {
            errorMessage
        }
    });

    window.dispatchEvent(cEvt);
}

// httpGet is a wrapper for fetch which includes error handling
async function httpGet(url, isInitialRequest) {

    // If isInitialRequest is true:
    //  - Errors are indicated on the main page and on the console.

    // If isInitialRequest is false:
    // - Errors are shown silently on the console only. Fetch will return
    //   `null` which indicates on the calling site to ignore the response,
    //   respectively any data update.

    // console.log('');
    // console.log('HTTP GET START');
    // console.log('url: %s', url);
    // console.log('isInitialRequest: %s', isInitialRequest);

    try {

        const response = await fetch(url);
        const data = response.json();

        return data;

    } catch(ex) {

        // console.log('HTTP GET ERROR');

        const errorMessage = `${ex} | URL: ${url}`;

        // Always show error in console
        console.error(errorMessage);

        if (isInitialRequest) {
            dispatchError(errorMessage);
        }

        // return null so that data updates are ignored
        return null;
    }
}

function debounce(fn, ms = 0) {

    let timeoutId;

    return function(...args) {

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}

function getAdvancedColors(sourceHexColor) {

    const colorItem = tinycolor(sourceHexColor);
    const isValidColor = colorItem.isValid();

    let color = isValidColor ? sourceHexColor : '#D1CFCF';
    let isLightColor = false;
    let darkColor = '#6E6D6D';

    // console.log('isValidColor: %s', isValidColor);

    if (isValidColor) {

        isLightColor = colorItem.isLight();
        darkColor = !isLightColor ? sourceHexColor :
            colorItem.darken(40).toString();

        // console.log('luminance: %s', colorItem.getLuminance());
    }

    // console.log('isLightColor: %s', isLightColor);
    // console.log('darkColor: %s', darkColor);
    // console.log('---');

    return {
        color,
        isLightColor,
        darkColor
    };
}

function intTo2DigitLabel(num) {

    if (num < 10) {
        return `0${num}`;
    }

    return num;
}

function getTimeLabel(hours, minutes) {

    if (typeof hours !== 'number' || typeof minutes !== 'number') {
        return '00:00';
    }

    // Returns a time label between 00:00 and 23:59
    const hoursLabel = intTo2DigitLabel(hours);
    const minutesLabel = intTo2DigitLabel(minutes);

    return `${hoursLabel}:${minutesLabel}`;
}

function getISODate() {

    // Returns the local date in ISO 8601 format

    const dateTime = new Date();

    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();

    const monthLabel = intTo2DigitLabel(month);
    const dayLabel = intTo2DigitLabel(day);

    return `${year}-${monthLabel}-${dayLabel}`;
}

function getInt(val) {

    if (typeof val === 'number') {

        // Round and omit decimals!
        return val.toFixed(0);
    }

    return 0;
}

function getFormattedNum(val, formatType) {

    // console.log('val typeof: %s', typeof val);

    let basicFormattedVal;

    switch (formatType) {

        case NUM_FORMATS.INTEGER:

            basicFormattedVal = INTL_NUM_FORMAT_INTEGER.format(val);
            break;

        case NUM_FORMATS.FLOAT_1_FRACTION_DIGIT:

            basicFormattedVal = INTL_NUM_FORMAT_FLOAT_1_FRACTION_DIGIT.format(
                val);
            break;
            
        case NUM_FORMATS.FLOAT_2_FRACTION_DIGITS:

            basicFormattedVal = INTL_NUM_FORMAT_FLOAT_2_FRACTION_DIGITS.format(
                val);
            break;

        default:

            console.error('Invalid formatType in getFormattedNum()');
            basicFormattedVal = val;
    }

    // If no thousands separator required
    if (val < 1000) {
        return basicFormattedVal;
    }

    // If thousands separator replace `,` with `'`.
    return basicFormattedVal.replaceAll(",", "’");
}

function getIsLonLatWithinBounds(lon, lat, bounds) {

    // bounds has the following format:
    // bounds = [
    //     [5.9559111595, 45.8179931641],  // southwest coordinates [lng, lat]
    //     [10.4920501709, 47.808380],     // northeast coordinates [lng, lat]
    // ];

    if (!lon || !lat || lon === 0 || lat === 0) {
        return false;
    }

    const southWestCoord = bounds[0];
    const northEastCoord = bounds[1];

    const lonMin = southWestCoord[0];
    const lonMax = northEastCoord[0];
    const latMin = southWestCoord[1];
    const latMax = northEastCoord[1];

    const isValidLon = lon >= lonMin && lon <= lonMax;
    const isValidLat = lat >= latMin && lat <= latMax;

    return isValidLon && isValidLat;
}

function getGeoBoundsOfMultipleLocations(items) {

    // Returns a bounds array as follows
    // const bounds = [
    //     [5.9559111595, 45.8179931641],  // southwest coordinates [lng, lat]
    //     [10.4920501709, 47.808380],     // northeast coordinates [lng, lat]
    // ];

    let lonList = [];
    let latList = [];

    const iMax = items.length - 1;

    for (let i = 0; i <= iMax; i++) {

        const {lon, lat} = items[i];

        lonList[i] = lon;
        latList[i] = lat;
    }

    lonList = lonList.sort((a, b) => a - b);
    latList = latList.sort((a, b) => a - b);

    const lonMin = lonList[0];
    const lonMax = lonList[iMax];
    const latMin = latList[0];
    const latMax = latList[iMax];

    const bounds = [
        [lonMin, latMin], // southwest coordinates [lng, lat]
        [lonMax, latMax] // northeast coordinates [lng, lat]
    ];

    // console.log('getGeoBoundsByOrgCode() - bounds: %o', bounds);

    return bounds;
}


export {
    dispatchError,
    httpGet,
    debounce,
    getAdvancedColors,
    intTo2DigitLabel,
    getTimeLabel,
    getISODate,
    getFormattedNum,
    getIsLonLatWithinBounds,
    getGeoBoundsOfMultipleLocations,
};
