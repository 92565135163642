
import {MAP_SETTINGS} from "../../constants";
import {dispatchError, getGeoBoundsOfMultipleLocations} from "../util";
import {getMapPopup} from './map-popup-html';
import {getMapPinElements} from './map-pin-elements';

/*
 * map-controller is a singleton object, it handles all tasks related to
 * the mapbox map.
 */

const MARKER_ANCHOR = 'bottom';

const DIMMED_COLOR = '#FFFFFF';
const DIMMED_OPACITY = '0.4';
const HIGHLIGHTED_OPACITY = '0.8';

let map;
let mapboxLang;

let mapMarkers = [/* Format per map marker object:
    clientCode:     String
    clientName:     String
    teamName:       String
    color:          String
    city:           String
    location:       String
    streetAddress:  String
    zip:            String
    lon:            Float
    lat:            Float
    divEl:          DomNode of container element
    gEl:            DomNode of SVG `g` element
    marker:         Mapbox marker instance
    popup:          Mapbox popup instance
*/];

let activeClientCode = '';

function initMap(accessToken, mapStyleUrl, containerElId, localeId) {

    try {

        mapboxgl.accessToken = accessToken;

        map = new mapboxgl.Map({
            container: containerElId,
            style: mapStyleUrl,
            // center: [lon, lat],      disabled because we use bounds!
            // zoom: initialMapZoom,    disabled because we use bounds!
            bounds: MAP_SETTINGS.COUNTRY_BOUNDS,
            maxBounds: MAP_SETTINGS.MAX_BOUNDS,
            maxZoom: MAP_SETTINGS.MAX_ZOOM,
            fitBoundsOptions: {
                padding: MAP_SETTINGS.COUNTRY_BOUNDS_PADDING
            }
        });

        //
        // Handle i18n
        //

        mapboxLang = new MapboxLanguage({
            defaultLanguage: localeId
        });

        map.addControl(mapboxLang);
    }
    catch (ex) {

        dispatchError(ex);
    }
}

function setMapLanguage(localeId) {

    map.setStyle(mapboxLang.setLanguage(map.getStyle(), localeId));
}

function removeMap() {

    // console.log('removeMap()');

    if (map) {
        removeMapMarkers();
        map.remove();
        mapMarkers = [];
    }
}

function removeMapMarkers() {

    // console.log('removeMapMarkers()');

    if (!map || mapMarkers.length === 0) {
        // console.log('NO MAP MARKERS TO REMOVE');
        return;
    }

    // console.log(`REMOVING ${mapMarkers.length} MAP MARKERS`);

    for (const item of mapMarkers) {

        const {popup, marker} = item;

        marker.remove();
        popup.remove();
    }

    mapMarkers = [];
}

/*
 * Creates and/or resets the map markers and popups. This should be called
 * whenever the locations or clients changes. It will remove any existing map
 * markers and popups before creating any new one.
 */
function setMapMarkers(params) {

    const {
        locations,
        nofLocations,
        clients,
    } = params;

    // console.log('locations: %o', locations);
    // console.log('clients: %o', clients);

    // Set activeClientCode on module level
    activeClientCode = params.activeClientCode;

    if (!map) {
        return;
    }

    // Remove any existing map markers
    removeMapMarkers();

    // If zero locations

    if (nofLocations === 0) {
        return;
    }

    // If locations

    const clientsByClientCode = {};

    for (const clientItem of clients) {
        const {clientCode} = clientItem;
        clientsByClientCode[clientCode] = clientItem;
    }

    // Create map markers and popups

    for (const locationItem of locations) {

        const {
            clientCode,
            teamName,
            city,
            location,
            streetAddress,
            zip,
            lon,
            lat,
        } = locationItem;

        const clientItem = clientsByClientCode[clientCode];
        const clientName = clientItem.name;
        const color = clientItem.color;

        const popup = getMapPopup({
            clientName,
            teamName,
            city,
            location,
            streetAddress,
            zip
        });

        const {divEl, gEl} = getMapPinElements(color, HIGHLIGHTED_OPACITY);

        const marker = new mapboxgl.Marker({
            element: divEl,
            anchor: MARKER_ANCHOR,
        })
            .setLngLat([lon, lat])
            .setPopup(popup)
            .addTo(map);

        mapMarkers.push({
            clientCode,
            clientName,
            teamName,
            color,
            city,
            location,
            streetAddress,
            zip,
            lon,
            lat,
            divEl,
            gEl,
            marker,
            popup
        });
    }

    // console.log('mapMarkers: %o', mapMarkers);
    
    // All map markers have been set in the client color, run
    // highlightMapMarkers if a specific client is active

    if (activeClientCode !== '') {

        highlightMapMarkers(activeClientCode);
    }
}

/*
 * Removes and re-creates the map markers and popups of the active client
 * code so that the highlighted map marker appear on top of all others. This
 * is important for multiple map markers at the same location.
 */
function removeAndRecreateMapMarkers() {

    for (const item of mapMarkers) {

        const {clientCode} = item;

        if (clientCode === activeClientCode) {

            const {
                clientName,
                teamName,
                city,
                location,
                streetAddress,
                zip,
                color,
                lon,
                lat,
                divEl,
                gEl,
                marker,
                popup
            } = item;

            // Remove existing map marker and popup
            marker.remove();
            popup.remove();

            // Recreate a new map marker and popup
            const newPopup = getMapPopup({
                clientName,
                teamName,
                city,
                location,
                streetAddress,
                zip
            });

            const newMapPin = getMapPinElements(color, HIGHLIGHTED_OPACITY);

            const newMarker = new mapboxgl.Marker({
                element: newMapPin.divEl,
                anchor: MARKER_ANCHOR,
            })
                .setLngLat([lon, lat])
                .setPopup(newPopup)
                .addTo(map);

            item.divEl = newMapPin.divEl;
            item.gEl = newMapPin.gEl;
            item.marker = newMarker;
            item.popup = newPopup;
        }
    }
}

function highlightMapMarkers(activeClientCodeParam) {

    // console.log('highlightMapMarkers()');
    // console.log('activeClientCodeParam: %s', activeClientCodeParam);
    // console.log('mapMarkers: %o', mapMarkers);

    // Set activeClientCode on module level
    activeClientCode = activeClientCodeParam;

    if (!map) {
        return;
    }

    for (const item of mapMarkers) {

        const {clientCode, color, divEl, gEl, popup} = item;

        // If a popup is open then remove (close) the popup.
        if (popup && popup.isOpen()) {
            popup.remove();
        }

        const isHighlight = activeClientCode === '' ||
            clientCode === activeClientCode;

        divEl.style.opacity = isHighlight ? HIGHLIGHTED_OPACITY :
            DIMMED_OPACITY;

        gEl.setAttribute("fill", isHighlight ? color : DIMMED_COLOR);
    }

    // If a particular client is highlighted
    if (activeClientCode !== '') {
        removeAndRecreateMapMarkers();
    }
}

function closeAnyOpenMapPopup() {

    if (!map) {
        return;
    }

    for (const item of mapMarkers) {

        const {popup} = item;

        if (popup && popup.isOpen()) {
            popup.remove();
        }
    }
}

function zoomIn() {

    if (!map) {
        return;
    }

    map.zoomIn();
}

function zoomOut() {

    if (!map) {
        return;
    }

    map.zoomOut();
}

function zoomCountryBounds() {

    if (!map) {
        return;
    }

    map.fitBounds(MAP_SETTINGS.COUNTRY_BOUNDS, {
        padding: MAP_SETTINGS.COUNTRY_BOUNDS_PADDING
    });
}

function zoomHighlightedMapMarkers() {

    if (!map) {
        return;
    }

    // if (activeClientCode === '') {
    //     zoomCountryBounds();
    //     return;
    // }

    // Get highlighted locations
    const highlightedLocations = activeClientCode === '' ? mapMarkers :
        mapMarkers.filter(item => {
            return item.clientCode === activeClientCode;
        });

    const nofHighlightedLocations = highlightedLocations.length;

    if (nofHighlightedLocations > 1) {

        // Multiple items to zoom, use fitBounds
        const bounds = getGeoBoundsOfMultipleLocations(highlightedLocations);

        map.fitBounds(bounds, {
            maxZoom: 10,
            padding: {top: 48, bottom:48, left: 48, right: 48}
        });
    }
    else if (nofHighlightedLocations === 1) {

        // Single item to zoom, use jumpTo
        const {lon, lat} = highlightedLocations[0];

        map.jumpTo({
            center: [lon, lat],
            zoom: 12,
        });
    }
}

function setMapStyleURL(url) {

    if (map) {
        map.setStyle(url);
    }
}

function resizeMap() {

    // Always delay so that any layout changes are settled before resize()
    setTimeout(() => {

        if (map) {
            map.resize();
        }

    }, 50);
}

export {
    initMap,
    setMapLanguage,
    removeMap,
    setMapMarkers,
    highlightMapMarkers,
    closeAnyOpenMapPopup,
    zoomIn,
    zoomOut,
    zoomCountryBounds,
    zoomHighlightedMapMarkers,
    setMapStyleURL,
    resizeMap
};
