<script>

    import {createEventDispatcher, onMount} from 'svelte';

    import {layout} from "../../../store";

    import TextItem from './TextItem.svelte';

    const dispatch = createEventDispatcher();

    export let clients;
    export let nofClients;
    export let activeClientCode;
    export let nofLocations;
    export let locDesc;

    layout

</script>

<style>

    .text-list {
      box-sizing: border-box;
      padding-top: 4px;
      width: 100%;
      height: 100%;
      overflow: auto;
    }

</style>

<div
        class="text-list"
        style="font-size:{$layout.bodyFontSize}rem"
>

    {#if nofClients > 1}

        <TextItem
                clientCode=""
                color="white"
                name={locDesc.all_organizations}
                count={nofLocations}
                isActive={activeClientCode === ''}
                on:client_code_change
        />

    {/if}

    {#each clients as {clientCode, color, name, logoURL, count} (clientCode)}

        <TextItem
                {clientCode}
                {color}
                {name}
                {count}
                isActive={clientCode === activeClientCode}
                on:client_code_change
        />

    {/each}

</div>
