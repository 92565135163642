// https://crisi.corris.ch/rest/public/agency/locations/corris/-
const locations = [
    {
        "date": "2021-11-09",
        "code": "SA",
        "client": "SWISSAID",
        "campaign": 7,
        "cluster": "SA-7",
        "color": "#ffcc00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "SA-Rudy S-FCH",
        "location": "La Poste 2",
        "location_number": 2230,
        "street_address": "Place St François",
        "zip": "1003",
        "city": "Lausanne",
        "latitude": 46.5198672,
        "longitude": 6.6327893
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 38,
        "cluster": "VPF-38",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "VP-Samir-FCH",
        "location": "Malley Lumières",
        "location_number": 2476,
        "street_address": "Chemin du Viaduc",
        "zip": "1008",
        "city": "Prilly",
        "latitude": 46.5273355,
        "longitude": 6.6020719
    },
    {
        "date": "2021-11-09",
        "code": "SA",
        "client": "SWISSAID",
        "campaign": 7,
        "cluster": "SA-7",
        "color": "#ffcc00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "VP-Samir-FCH",
        "location": "La Poste de Chailly ",
        "location_number": 2558,
        "street_address": "Avenue de Chailly",
        "zip": "1012",
        "city": "Lausanne",
        "latitude": 46.5244364,
        "longitude": 6.653525
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 38,
        "cluster": "VPF-38",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "VP-Samir-FCH",
        "location": "SBB-CFF-FFS",
        "location_number": 2188,
        "street_address": "Gare",
        "zip": "1020",
        "city": "Renens VD",
        "latitude": 46.53701,
        "longitude": 6.5789142
    },
    {
        "date": "2021-11-09",
        "code": "SA",
        "client": "SWISSAID",
        "campaign": 7,
        "cluster": "SA-7",
        "color": "#ffcc00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "SA-Rudy S-FCH",
        "location": "La Poste",
        "location_number": 2554,
        "street_address": "Poste",
        "zip": "1024",
        "city": "Ecublens VD",
        "latitude": 46.5198917,
        "longitude": 6.5688168
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "HEL-Alex L-FCH",
        "location": "La Poste",
        "location_number": 2669,
        "street_address": "Rte de l'Ancienne Ciblerie",
        "zip": "1095",
        "city": "Lutry",
        "latitude": 46.5049924,
        "longitude": 6.6883967
    },
    {
        "date": "2021-11-09",
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "GC-Telma-FCH",
        "location": "La Poste 1",
        "location_number": 2458,
        "street_address": "Rue des Charpentiers",
        "zip": "1110",
        "city": "Morges",
        "latitude": 46.5105647,
        "longitude": 6.4973524
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "HEL-Alex L-FCH",
        "location": "La Poste des Charmilles",
        "location_number": 2586,
        "street_address": "Promenade de l'Europe",
        "zip": "1203",
        "city": "Genève",
        "latitude": 46.2082468,
        "longitude": 6.1241668
    },
    {
        "date": "2021-11-09",
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "GC-Telma-FCH",
        "location": "Boulevard Carl- Vogt 24 ",
        "location_number": 2627,
        "street_address": "Boulevard Carl-Vogt",
        "zip": "1205",
        "city": "Genève",
        "latitude": 46.1952103,
        "longitude": 6.1408431
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 38,
        "cluster": "VPF-38",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "VP-Samir-FCH",
        "location": "Av. des Communes-Réunies",
        "location_number": 2136,
        "street_address": "Av. des Communes-Réunies",
        "zip": "1212",
        "city": "Grand-Lancy",
        "latitude": 46.1794814,
        "longitude": 6.1216299
    },
    {
        "date": "2021-11-09",
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "GC-Telma-FCH",
        "location": "en Ville",
        "location_number": 2138,
        "street_address": "Place Graveson",
        "zip": "1226",
        "city": "Thônex",
        "latitude": 46.1932196,
        "longitude": 6.2009684
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "HEL-Alex L-FCH",
        "location": "Place Bel-Air",
        "location_number": 2656,
        "street_address": "Place Bel-Air",
        "zip": "1400",
        "city": "Yverdon-les-Bains",
        "latitude": 46.7805534,
        "longitude": 6.637099
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "PI-Fabien B-FCH",
        "location": "La Poste",
        "location_number": 2599,
        "street_address": "Rue Nicolas-Glasson",
        "zip": "1630",
        "city": "Bulle 1",
        "latitude": 46.6172331,
        "longitude": 7.0554391
    },
    {
        "date": "2021-11-09",
        "code": "AEA",
        "client": "Aide et Action",
        "campaign": 11,
        "cluster": "AEA-11",
        "color": "#ffff00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "AeA-Fabien-FCH",
        "location": "Rue de Locarno",
        "location_number": 2346,
        "street_address": "Rue de Locarno",
        "zip": "1700",
        "city": "Fribourg",
        "latitude": 46.7999781,
        "longitude": 7.1512894
    },
    {
        "date": "2021-11-09",
        "code": "AEA",
        "client": "Aide et Action",
        "campaign": 11,
        "cluster": "AEA-11",
        "color": "#ffff00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "AeA-Fabien-FCH",
        "location": "Place de la Paix",
        "location_number": 2063,
        "street_address": "Place de la Paix",
        "zip": "1820",
        "city": "Montreux",
        "latitude": 46.43134,
        "longitude": 6.91073
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "PI-Alexander L-FCH",
        "location": "SBB-CFF-FFS",
        "location_number": 2106,
        "street_address": "Gare",
        "zip": "1860",
        "city": "Aigle",
        "latitude": 46.31537305,
        "longitude": 6.9626366178525
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "PI-Alexander L-FCH",
        "location": "La Poste",
        "location_number": 2327,
        "street_address": "Av. du Théâtre (en ville)",
        "zip": "1870",
        "city": "Monthey 2",
        "latitude": 46.253686034597,
        "longitude": 6.9486466349488
    },
    {
        "date": "2021-11-09",
        "code": "SA",
        "client": "SWISSAID",
        "campaign": 7,
        "cluster": "SA-7",
        "color": "#ffcc00",
        "campaigntype": "F2F",
        "region": "FCH",
        "team": "SA-Rudy S-FCH",
        "location": "SBB-CFF-FFS",
        "location_number": 2115,
        "street_address": "Gare",
        "zip": "2000",
        "city": "Neuchâtel",
        "latitude": 46.9965783,
        "longitude": 6.9358276
    },
    {
        "date": "2021-11-09",
        "code": "MSG",
        "client": "Schweizerische Multiple Sklerose Gesellschaft",
        "campaign": 19,
        "cluster": "MSG-19",
        "color": "#bfbfbf",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "MSG-Ramon-DCH",
        "location": "SBB-CFF-FFS Grenchen Süd",
        "location_number": 538,
        "street_address": "Bahnhof",
        "zip": "2540",
        "city": "Grenchen",
        "latitude": 47.1893327,
        "longitude": 7.4001578
    },
    {
        "date": "2021-11-09",
        "code": "CSP",
        "client": "Stiftung Sozialprojekte Concordia",
        "campaign": 55,
        "cluster": "CSP-55",
        "color": "#bcd43f",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "CSP-Frauke K-DCH",
        "location": "Bärenplatz",
        "location_number": 21,
        "street_address": "Bärenplatz",
        "zip": "3011",
        "city": "Bern",
        "latitude": 46.948155555556,
        "longitude": 7.4433083333333
    },
    {
        "date": "2021-11-09",
        "code": "MSG",
        "client": "Schweizerische Multiple Sklerose Gesellschaft",
        "campaign": 19,
        "cluster": "MSG-19",
        "color": "#bfbfbf",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "MSG-Ramon-DCH",
        "location": "Bewegungszone",
        "location_number": 1362,
        "street_address": "Zentrumplatz",
        "zip": "3303",
        "city": "Jegenstorf",
        "latitude": 47.0500163,
        "longitude": 7.50762
    },
    {
        "date": "2021-11-09",
        "code": "MSG",
        "client": "Schweizerische Multiple Sklerose Gesellschaft",
        "campaign": 19,
        "cluster": "MSG-19",
        "color": "#bfbfbf",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "MSG-Ramon-DCH",
        "location": "Die Post ",
        "location_number": 1090,
        "street_address": "Bahnhofstrasse",
        "zip": "3550",
        "city": "Langnau im Emmental",
        "latitude": 46.9392431,
        "longitude": 7.7848845
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "PI-Ramon L-DCH",
        "location": "Vorplatz ReparNStore",
        "location_number": 1017,
        "street_address": "Freienhofgasse",
        "zip": "3600",
        "city": "Thun",
        "latitude": 46.75783,
        "longitude": 7.63094
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "PI-Ramon L-DCH",
        "location": "Marktplatz",
        "location_number": 1112,
        "street_address": "Marktplatz",
        "zip": "3661",
        "city": "Uetendorf",
        "latitude": 46.774046503553,
        "longitude": 7.5799362563053
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 6,
        "cluster": "VPF-6",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "VPF-Tristan A-DCH",
        "location": "Die Post St. Clara",
        "location_number": 1024,
        "street_address": "Claragraben",
        "zip": "4001",
        "city": "Basel",
        "latitude": 47.5626209,
        "longitude": 7.5941262
    },
    {
        "date": "2021-11-09",
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "GC-Cedric G-DCH",
        "location": "Igelweid",
        "location_number": 1,
        "street_address": "Igelweid",
        "zip": "5000",
        "city": "Aarau",
        "latitude": 47.392055555556,
        "longitude": 8.0464416666667
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "PI-Thomas S-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 199,
        "street_address": "Bahnhof",
        "zip": "5600",
        "city": "Lenzburg",
        "latitude": 47.390905555556,
        "longitude": 8.1706972222222
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "PI-Cedric G-DCH",
        "location": "Bahnhofplatz",
        "location_number": 93,
        "street_address": "Bahnhofplatz",
        "zip": "6003",
        "city": "Luzern",
        "latitude": 47.051027777778,
        "longitude": 8.3089722222222
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "PI-Cedric G-DCH",
        "location": "Hauptpost",
        "location_number": 1567,
        "street_address": "Bahnhofplatz",
        "zip": "6003",
        "city": "Luzern",
        "latitude": 47.050826890592,
        "longitude": 8.3087795739993
    },
    {
        "date": "2021-11-09",
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "PI-Cedric G-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 201,
        "street_address": "Bahnhof",
        "zip": "6003",
        "city": "Luzern",
        "latitude": 47.050247222222,
        "longitude": 8.3098277777778
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "La Posta",
        "location_number": 9035,
        "street_address": "Viale Stazione",
        "zip": "6500",
        "city": "Bellinzona",
        "latitude": 46.1931414,
        "longitude": 9.0254477
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "La Posta",
        "location_number": 9059,
        "street_address": "Via Bellinzona",
        "zip": "6512",
        "city": "Giubiasco",
        "latitude": 46.1770663,
        "longitude": 9.0132488
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "Locarno - Dialogatore mobile",
        "location_number": 9185,
        "street_address": "",
        "zip": "6600",
        "city": "Locarno",
        "latitude": 46.1695112,
        "longitude": 8.7954217
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "SBB-CFF-FFS Locarno",
        "location_number": 9018,
        "street_address": "Stazione FFS Locarno",
        "zip": "6600",
        "city": "Muralto",
        "latitude": 46.1720965,
        "longitude": 8.8007588
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "La Posta",
        "location_number": 9110,
        "street_address": "Via San Gottardo",
        "zip": "6648",
        "city": "Minusio",
        "latitude": 46.1753434,
        "longitude": 8.8114242
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "HEL-Matteo B-ICH",
        "location": "La Posta Stazione ",
        "location_number": 9056,
        "street_address": "Viale Stefano Franscini",
        "zip": "6850",
        "city": "Mendrisio Stazione",
        "latitude": 45.8459176,
        "longitude": 9.0227006
    },
    {
        "date": "2021-11-09",
        "code": "WWF",
        "client": "WWF Schweiz",
        "campaign": 22,
        "cluster": "WWF-22",
        "color": "#ffffce",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "WWF-Rania R-ICH",
        "location": "SBB-CFF-FFS",
        "location_number": 9020,
        "street_address": "Piazzale Stazione",
        "zip": "6900",
        "city": "Lugano",
        "latitude": 46.00552925,
        "longitude": 8.9471164918629
    },
    {
        "date": "2021-11-09",
        "code": "WWF",
        "client": "WWF Schweiz",
        "campaign": 22,
        "cluster": "WWF-22",
        "color": "#ffffce",
        "campaigntype": "F2F",
        "region": "ICH",
        "team": "WWF-Rania R-ICH",
        "location": "Via Peri",
        "location_number": 9155,
        "street_address": "Via Peri",
        "zip": "6900",
        "city": "Lugano",
        "latitude": 46.0057714,
        "longitude": 8.9507193
    },
    {
        "date": "2021-11-09",
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "GC-Cedric G-DCH",
        "location": "SBB-CFF-FFS Stadelhofen",
        "location_number": 236,
        "street_address": "Bahnhof",
        "zip": "8001",
        "city": "Zürich",
        "latitude": 47.366436111111,
        "longitude": 8.5486194444444
    },
    {
        "date": "2021-11-09",
        "code": "SKS",
        "client": "Stiftung Konsumentenschutz",
        "campaign": 15,
        "cluster": "SKS-15",
        "color": "#048000",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "SKS-Yassin K-DCH",
        "location": "SBB-CFF-FFS Hauptbahnhof F1",
        "location_number": 750,
        "street_address": "Bahnhof",
        "zip": "8001",
        "city": "Zürich",
        "latitude": 47.57273835,
        "longitude": 8.516541024186
    },
    {
        "date": "2021-11-09",
        "code": "CSP",
        "client": "Stiftung Sozialprojekte Concordia",
        "campaign": 55,
        "cluster": "CSP-55",
        "color": "#bcd43f",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "CSP-Frauke K-DCH",
        "location": "Die Post Sihlpost",
        "location_number": 470,
        "street_address": "Kasernenstrasse",
        "zip": "8004",
        "city": "Zürich",
        "latitude": 47.3760407,
        "longitude": 8.5338668
    },
    {
        "date": "2021-11-09",
        "code": "AMI",
        "client": "Amnesty International Schweizer Sektion",
        "campaign": 17,
        "cluster": "AMI-17",
        "color": "#fe03ff",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "AMI-Roman H-DCH",
        "location": "Seefeld",
        "location_number": 318,
        "street_address": "Seefeld / Höschgasse",
        "zip": "8008",
        "city": "Zürich",
        "latitude": 47.357975,
        "longitude": 8.5543916666667
    },
    {
        "date": "2021-11-09",
        "code": "CSP",
        "client": "Stiftung Sozialprojekte Concordia",
        "campaign": 55,
        "cluster": "CSP-55",
        "color": "#bcd43f",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "CSP-Frauke K-DCH",
        "location": "SBB-CFF-FFS Oerlikon F1",
        "location_number": 235,
        "street_address": "Bahnhof",
        "zip": "8050",
        "city": "Zürich",
        "latitude": 47.411291666667,
        "longitude": 8.5440777777778
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 6,
        "cluster": "VPF-6",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "VPF-Tristan A-DCH",
        "location": "EKZ Seen",
        "location_number": 789,
        "street_address": "Kanzleistrasse",
        "zip": "8403",
        "city": "Winterthur",
        "latitude": 47.4875782,
        "longitude": 8.7663357
    },
    {
        "date": "2021-11-09",
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "HEL-Roman H-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 187,
        "street_address": "Bahnhof",
        "zip": "8600",
        "city": "Dübendorf",
        "latitude": 47.400177777778,
        "longitude": 8.6227888888889
    },
    {
        "date": "2021-11-09",
        "code": "AMI",
        "client": "Amnesty International Schweizer Sektion",
        "campaign": 17,
        "cluster": "AMI-17",
        "color": "#fe03ff",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "AMI-Roman H-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 222,
        "street_address": "Bahnhof",
        "zip": "8610",
        "city": "Uster",
        "latitude": 47.350352777778,
        "longitude": 8.7180333333333
    },
    {
        "date": "2021-11-09",
        "code": "KNH",
        "client": "Kindernothilfe Schweiz",
        "campaign": 33,
        "cluster": "KNH-33",
        "color": "#cc9900",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "KNH-Ali M-DCH",
        "location": "Die Post",
        "location_number": 878,
        "street_address": "Untere Bahnhofstrasse",
        "zip": "8640",
        "city": "Rapperswil SG",
        "latitude": 47.2265048,
        "longitude": 8.8184583
    },
    {
        "date": "2021-11-09",
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 6,
        "cluster": "VPF-6",
        "color": "#fcd5b4",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "VPF-Tristan A-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 207,
        "street_address": "Bahnhof",
        "zip": "8640",
        "city": "Rapperswil SG",
        "latitude": 47.2253,
        "longitude": 8.8173361111111
    },
    {
        "date": "2021-11-09",
        "code": "KNH",
        "client": "Kindernothilfe Schweiz",
        "campaign": 33,
        "cluster": "KNH-33",
        "color": "#cc9900",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "KNH-Ali M-DCH",
        "location": "Gemeindehausstrasse",
        "location_number": 778,
        "street_address": "Gemeindehausstrasse",
        "zip": "8750",
        "city": "Glarus",
        "latitude": 47.0397631,
        "longitude": 9.0701657
    },
    {
        "date": "2021-11-09",
        "code": "KNH",
        "client": "Kindernothilfe Schweiz",
        "campaign": 33,
        "cluster": "KNH-33",
        "color": "#cc9900",
        "campaigntype": "TRT",
        "region": "DCH",
        "team": "KNH-Ali M-DCH",
        "location": "Die Post ",
        "location_number": 1239,
        "street_address": "Ziegelbrückstrasse",
        "zip": "8867",
        "city": "Niederurnen",
        "latitude": 47.1274064,
        "longitude": 9.0553042
    },
    {
        "date": "2021-11-09",
        "code": "SES",
        "client": "Schweizerische Energie-Stiftung",
        "campaign": 52,
        "cluster": "SES-52",
        "color": "#d1ee53",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "SES-Elena K-DCH",
        "location": "Die Post 1 ",
        "location_number": 466,
        "street_address": "Bahnhofplatz",
        "zip": "9000",
        "city": "St. Gallen",
        "latitude": 47.4532046,
        "longitude": 9.6370633
    },
    {
        "date": "2021-11-09",
        "code": "SES",
        "client": "Schweizerische Energie-Stiftung",
        "campaign": 52,
        "cluster": "SES-52",
        "color": "#d1ee53",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "SES-Elena K-DCH",
        "location": "Die Post Brühltor",
        "location_number": 580,
        "street_address": "Brühlgasse",
        "zip": "9004",
        "city": "St. Gallen",
        "latitude": 47.4258355,
        "longitude": 9.377345
    },
    {
        "date": "2021-11-09",
        "code": "TDH",
        "client": "Terre des Hommes Schweiz",
        "campaign": 12,
        "cluster": "TDH-12",
        "color": "#feefd9",
        "campaigntype": "F2F",
        "region": "DCH",
        "team": "TDH-Nils F-DCH",
        "location": "SBB-CFF-FFS",
        "location_number": 227,
        "street_address": "Bahnhof",
        "zip": "9500",
        "city": "Wil SG",
        "latitude": 47.462680555556,
        "longitude": 9.0413388888889
    }
];

// https://crisi.corris.ch/rest/public/agency/clients/corris/20211109
const clients = [
    {
        "id": 16,
        "code": "AEA",
        "client": "Aide et Action",
        "campaign": 11,
        "cluster": "AEA-11",
        "color": "#ffff00",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@1591c2dd688e383.30524209"
    },
    {
        "id": 20,
        "code": "AMI",
        "client": "Amnesty International Schweizer Sektion",
        "campaign": 17,
        "cluster": "AMI-17",
        "color": "#fe03ff",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@8591af52dc73b76.32530279"
    },
    {
        "id": 5,
        "code": "GC",
        "client": "Green Cross Schweiz",
        "campaign": 20,
        "cluster": "GC-20",
        "color": "#cc99fd",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@a591c3414ca1ec3.45233675"
    },
    {
        "id": 1,
        "code": "HEL",
        "client": "HELVETAS Swiss Intercooperation",
        "campaign": 1,
        "cluster": "HEL-1",
        "color": "#3acbcb",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@a5821cb74aa6a42.43744102"
    },
    {
        "id": 28,
        "code": "KNH",
        "client": "Kindernothilfe Schweiz",
        "campaign": 33,
        "cluster": "KNH-33",
        "color": "#cc9900",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@9591af60316e254.55548399"
    },
    {
        "id": 21,
        "code": "PI",
        "client": "Pro Infirmis",
        "campaign": 18,
        "cluster": "PI-18",
        "color": "#ff99cc",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@3591c1a0b6ca105.05148462"
    },
    {
        "id": 45,
        "code": "SES",
        "client": "Schweizerische Energie-Stiftung",
        "campaign": 52,
        "cluster": "SES-52",
        "color": "#d1ee53",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@95ed7d182a45636.30288472"
    },
    {
        "id": 30,
        "code": "MSG",
        "client": "Schweizerische Multiple Sklerose Gesellschaft",
        "campaign": 19,
        "cluster": "MSG-19",
        "color": "#bfbfbf",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@65acf088aa1f137.49836496"
    },
    {
        "id": 19,
        "code": "SKS",
        "client": "Stiftung Konsumentenschutz",
        "campaign": 15,
        "cluster": "SKS-15",
        "color": "#048000",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@65c80dcaa57dc92.41802082"
    },
    {
        "id": 46,
        "code": "CSP",
        "client": "Stiftung Sozialprojekte Concordia",
        "campaign": 55,
        "cluster": "CSP-55",
        "color": "#bcd43f",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@25fd1ed84776792.95418095"
    },
    {
        "id": 13,
        "code": "SA",
        "client": "SWISSAID",
        "campaign": 7,
        "cluster": "SA-7",
        "color": "#ffcc00",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@8591c461a839ad2.00282856"
    },
    {
        "id": 8,
        "code": "TDH",
        "client": "Terre des Hommes Schweiz",
        "campaign": 12,
        "cluster": "TDH-12",
        "color": "#feefd9",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@45821cce1756007.44299138"
    },
    {
        "id": 10,
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 38,
        "cluster": "VPF-38",
        "color": "#fcd5b4",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@75821cd157a5625.89274590"
    },
    {
        "id": 10,
        "code": "VPF",
        "client": "VIER PFOTEN Schweiz",
        "campaign": 6,
        "cluster": "VPF-6",
        "color": "#fcd5b4",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@75821cd157a5625.89274590"
    },
    {
        "id": 2,
        "code": "WWF",
        "client": "WWF Schweiz",
        "campaign": 22,
        "cluster": "WWF-22",
        "color": "#ffffce",
        "logo_file": "https://crisi.corris.ch/rest/public/filedb/@6582333a5a362c6.45692714"
    }
];

// https://crisi.corris.ch/rest/public/agency/statistics/corris/20211109
const statistics = {
    "teams": 22,
    "employee_days": 113,
    "donors": 360,
    "donors_approved": 192,
    "amount_average": 172.49436111111112,
    "amount_total": 62097.97,
    "top_donors": [
        {
            "name": "H. Carlo",
            "approved": 8
        },
        {
            "name": "K. Kimberly",
            "approved": 8
        },
        {
            "name": "H. Sibyll",
            "approved": 8
        }
    ],
    "top_totals": [
        {
            "name": "H. Sibyll",
            "total": 2400
        },
        {
            "name": "R. Julie",
            "total": 2379.9900000000002
        },
        {
            "name": "K. Soukaina",
            "total": 1980.02
        }
    ],
    "top_highest": [
        {
            "name": "R. Julie",
            "max_amount": 1800
        },
        {
            "name": "K. Soukaina",
            "max_amount": 1140
        },
        {
            "name": "S. Thomas",
            "max_amount": 600
        }
    ]
};

const statisticsWithFutureDate = {
    "teams": 0,
    "employee_days": 0,
    "donors": 0,
    "donors_approved": 0,
    "amount_average": 0,
    "amount_total": 0,
    "top_donors": [],
    "top_totals": [],
    "top_highest": []
};

export const sampleData = {
    locations,
    clients,
    statistics
};
