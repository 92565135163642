import AppMain from './AppMain.svelte';

let appMain;

try {
	appMain = new AppMain({
		target: document.body,
		// Disabled urlParamsString because it might cause the problems
		// on Xoro tablet.
		// props: {
		// 	urlParamsString: window.location.search
		// }
	});
}
catch (ex) {
	const msg = `new AppMain() Error: ${ex}`;
	alert(msg);
}

export default appMain;
