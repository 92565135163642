<script>

    import {createEventDispatcher} from 'svelte';

    import {
        zoomIn,
        zoomOut,
        zoomCountryBounds,
        zoomHighlightedMapMarkers
    } from "../../services/map-controller";

    const dispatch = createEventDispatcher();

    export let locDesc;

    // Zoom Actions
    const ZOOM_COUNTRY_BOUNDS = 'ZOOM_COUNTRY_BOUNDS';
    const ZOOM_HIGHLIGHTED_MAP_MARKERS = 'ZOOM_HIGHLIGHTED_MAP_MARKERS';
    const ZOOM_OUT = 'ZOOM_OUT';
    const ZOOM_IN = 'ZOOM_IN';

    function handleZoomClick(zoomAction) {

        switch (zoomAction) {

            case ZOOM_COUNTRY_BOUNDS:

                zoomCountryBounds();
                // dispatch('zoom_country_bounds', {});
                break;

            case ZOOM_HIGHLIGHTED_MAP_MARKERS:

                zoomHighlightedMapMarkers();
                break;

            case ZOOM_OUT:

                zoomOut();
                break;

            case ZOOM_IN:

                zoomIn();
                break;

            default:

                // No default case

        }
    }

</script>

<style>

  .zoom-controls {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    left: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: white;
    z-index: 100;
  }

  .zoom-controls > div {
    border-right: 1px solid lightgray;
  }

  .zoom-controls > div:last-child {
    border-right: none;
  }

  .zoom-controls  button {
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.4rem;
    font-weight: 300;
    width: 58px;
    height: 42px;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    background-color: transparent;
    /*border-radius: 6px;*/
  }

  .zoom-controls  button:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  button > svg {
    margin-top: 3px;
    opacity: 0.6;
    /*border: 1px solid red;*/
  }
  button:hover > svg {
    opacity: 0.9;
  }


</style>

<div class="zoom-controls">

    <div>
        <button
                type="button"
                title={locDesc.zoom_country_bounds}
                on:click={() => handleZoomClick(ZOOM_COUNTRY_BOUNDS)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M0,6V2A2,2,0,0,1,2,0V2H2V6ZM6,2V0H7V2ZM2,2V0H6V2Z" transform="translate(1 3)" fill="#101010"/><path d="M2,6V4H6V6ZM0,4H2V6A2.005,2.005,0,0,1,0,4ZM6,6V4H7V6ZM0,4V0H2V4Z" transform="translate(1 13)" fill="#101010"/><path d="M5,6V2H5V0A2,2,0,0,1,7,2V6ZM1,2H0V0H5V2Z" transform="translate(14 3)" fill="#101010"/><path d="M5,4H7A2.005,2.005,0,0,1,5,6ZM1,6H0V4H5V6ZM5,4V0H7V4Z" transform="translate(14 13)" fill="#101010"/><rect width="22" height="22" fill="none"/></svg>
        </button>
    </div>

    <div>
        <button
                type="button"
                title={locDesc.zoom_highlighted_clients}
                on:click={() => handleZoomClick(ZOOM_HIGHLIGHTED_MAP_MARKERS)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M0,0H22V22H0Z" fill="none"/><path d="M16.25,9.38a5.26,5.26,0,1,0-10.5,0c0,2.106,1.706,4.9,5.25,8.226C14.544,14.276,16.25,11.486,16.25,9.38ZM11,2a7.064,7.064,0,0,1,7,7.38Q18,13.862,11,20,4,13.857,4,9.38A7.064,7.064,0,0,1,11,2Z"/></svg>
        </button>
    </div>

    <div>
        <button
                type="button"
                title={locDesc.zoom_out}
                on:click={() => handleZoomClick(ZOOM_OUT)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><rect width="10" height="2" transform="translate(6 10)" fill="#060706"/><rect width="22" height="22" fill="none" opacity="0.22"/></svg>
        </button>
    </div>

    <div>
        <button
                type="button"
                title={locDesc.zoom_in}
                on:click={() => handleZoomClick(ZOOM_IN)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g transform="translate(-324 -266)"><rect width="10" height="2" transform="translate(330 276)" fill="#060706"/><rect width="2" height="10" transform="translate(334 272)" fill="#060706"/></g><rect width="22" height="22" fill="none"/></svg>
        </button>
    </div>

</div>
