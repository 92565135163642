<script>

    import StatisticsItem from './StatisticsItem.svelte';

    export let label;
    export let isCurrency = false;
    export let items;
    export let format;
    export let locDesc;

</script>

<style>

    .top-performers {
      font-weight: 300;
    }

    .title {
      font-weight: 400;
      margin-top: 2px;
      margin-bottom: 5px;;
    }

    /* Currency characters */
    span {
      font-weight: 300;
    }

</style>

<div class="top-performers">

    <div class="title">

        {label}&nbsp;

        {#if isCurrency}

            <span>({locDesc.currency_symbol})</span>

        {/if}

    </div>

        {#each items as {name, value}}

            <StatisticsItem
                    label={name}
                    value={value}
                    {format}
            />

        {/each}

</div>
