<script>

    import {createEventDispatcher} from 'svelte';

    import TextSymbol from './TextSymbol.svelte';

    export let clientCode;
    export let color;
    export let name;
    export let count;
    export let isActive;

    const dispatch = createEventDispatcher();

    function handleOrgCodeClick(nextClientCode) {

        // console.log('nextClientCode: %s', nextClientCode);

        dispatch('client_code_change', {
            nextClientCode
        });
    }

</script>

<style>

  button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 48px;
    margin: 0;
    padding: 0;
    width: 100%;
    border-color: transparent;
    background-color: transparent;
  }

  button > div {
    /*border: 1px solid red;*/
  }

  button > div:first-child {

  }

  button > div:nth-child(2) {
    flex-grow: 1;
  }

  button:hover {
    background-color: #efefef;
  }

  .symbol-box {
    margin: 0 16px;
  }

  .client-name {
    display: block;
    text-align: left;
    line-height: 1.1;
    /*border: 1px solid blue;*/
  }

  .count {
    display: block;
    font-weight: 300;
    padding: 0 17px;
    text-align: right;
  }

</style>

<button
        type="button"
        value={clientCode}
        on:click={() => handleOrgCodeClick(clientCode)}
>

    <div>
        <div class="symbol-box">

            <TextSymbol
                    {color}
            />

        </div>
    </div>

    <div>
        <span
                class="client-name"
                style={!isActive ? '' : 'font-weight:500;'}
        >
            {name}
        </span>
    </div>

    <div>
        <span
                class="count"
                style={!isActive ? '' : 'font-weight:400;'}
        >
            {count}
        </span>
    </div>

</button>
