<script>

    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    const LINKS = APP_CONFIG.LINKS;

    export let isAltLayout;
    export let localeId;
    export let locDesc;

    // KHP 10/11/2021
    // Disabled getHomePageURL(). We can ignore the localeId in URLs because
    // language specific URLs are set in the respective locale on the backend.
    // function getHomePageURL(localeId, baseURL) {
    //
    //     // If German
    //     if (localeId === 'de') {
    //         return baseURL;
    //     }
    //
    //     // If French or Italian
    //    return `${baseURL}/${localeId}/`;
    // }

    function handleClick(evt) {

        const el = evt.currentTarget;
        const href = el.getAttribute('href');

        // console.log('href: %s', href);
        dispatch('external_link_click', {URL: href});
    }

</script>


<style>

  .links-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 17px 0;
  }

  .links-bar--alternative {
    justify-content: flex-end;
    margin: 18px 1px 0 0;
  }

  .links-bar > div {
    margin: 0 9px;
  }

  .links-bar > div:first-child {
    margin-left: 0;
  }

  .links-bar > div:last-child {
    margin-right: 0;
  }

  a:link,
  a:visited,
  a:hover {
    display: block;
    width: 50px;
    height: 50px;
    opacity: 0.9;
  }

  svg {
    fill: #35a4a7;
  }

  svg:hover {
    fill: #239598;
  }

</style>

<div class={!isAltLayout ? 'links-bar' : 'links-bar links-bar--alternative'}>

    <!-- NEWS Link (opens in iframe within Liveticker app!) -->
    {#if LINKS.hasOwnProperty('NEWS') && LINKS.NEWS !== ''}
        <div>
            <a
                    href={LINKS.NEWS}
                    alt={locDesc.news}
                    title={locDesc.news}
                    on:click|preventDefault={handleClick}
            >

                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <rect width="50" height="50" rx="6" fill="#35a4a7"/><path d="M3.1-3.938,2.31-5.533H2.277V0H.9V-7.678H2.5L4.807-3.74l.792,1.6h.033V-7.678H7.007V0h-1.6ZM8.943,0V-7.678H14v1.287H10.4v1.859h3.19v1.287H10.4v1.958H14V0Zm7.9,0L15.059-7.678h1.452l.759,3.685.473,2.354h.033l.55-2.354.88-3.685h1.606l.88,3.685.539,2.354h.033l.484-2.354.781-3.685h1.386L23.078,0H21.439L20.46-4.059l-.473-2.024h-.022L19.47-4.059,18.491,0ZM28.633.132a3.643,3.643,0,0,1-1.677-.353,3.9,3.9,0,0,1-1.182-.926l.979-.948a2.8,2.8,0,0,0,.885.7,2.426,2.426,0,0,0,1.083.24,1.53,1.53,0,0,0,1.012-.289.971.971,0,0,0,.341-.78.885.885,0,0,0-.22-.622,1.476,1.476,0,0,0-.825-.349L28.3-3.305Q26-3.669,26-5.541a2.214,2.214,0,0,1,.193-.936,1.991,1.991,0,0,1,.556-.716,2.571,2.571,0,0,1,.874-.457,3.891,3.891,0,0,1,1.161-.16,3.731,3.731,0,0,1,1.518.287,2.981,2.981,0,0,1,1.111.849l-.99.937a2.276,2.276,0,0,0-.693-.567,2.15,2.15,0,0,0-1.023-.218,1.563,1.563,0,0,0-.941.234.776.776,0,0,0-.314.66.783.783,0,0,0,.253.643,1.787,1.787,0,0,0,.814.305l.715.132A3.28,3.28,0,0,1,30.96-3.8a1.973,1.973,0,0,1,.556,1.492,2.49,2.49,0,0,1-.193,1,2.154,2.154,0,0,1-.561.771,2.557,2.557,0,0,1-.9.5A3.909,3.909,0,0,1,28.633.132Z" transform="translate(9 15)" fill="#fff"/><path d="M2,0A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2M2-1.8H26A3.8,3.8,0,0,1,29.8,2V16A3.8,3.8,0,0,1,26,19.8H2A3.8,3.8,0,0,1-1.8,16V2A3.8,3.8,0,0,1,2-1.8Z" transform="translate(11 22)" fill="#fefdfd"/><rect width="18" height="1.8" rx="0.9" transform="translate(16 25)" fill="#fff"/><rect width="18" height="1.8" rx="0.9" transform="translate(16 30)" fill="#fff"/><rect width="18" height="1.8" rx="0.9" transform="translate(16 35)" fill="#fff"/>
                </svg>
            </a>
        </div>
    {/if}

    <!-- FACEBOOK Link (opens in new tab) -->
    {#if LINKS.hasOwnProperty('FACEBOOK') && LINKS.FACEBOOK !== ''}
        <div>

            <a href={LINKS.FACEBOOK} target="_blank" alt="Facebook" title="Facebook">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <rect width="50" height="50" rx="6" /><path d="M11.3,34H4.58V17.24H0V11.784H4.58V7.9c0-2.931.822-5.334,3.3-6.775A10.964,10.964,0,0,1,13.31,0c1.418,0,3.644.286,3.644.286V5.353H14.616c-2.383,0-3.313.725-3.313,2.728v3.7h5.562l-.5,5.457H11.3Z" transform="translate(17 8)" fill="#fbfcfe"/>
                </svg>
            </a>
        </div>
    {/if}

    <!-- INSTAGRAM Link (opens in new tab) -->
    {#if LINKS.hasOwnProperty('INSTAGRAM') && LINKS.INSTAGRAM !== ''}
        <div>

            <a href={LINKS.INSTAGRAM} target="_blank" alt="Instagram" title="Instagram">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <rect width="50" height="50" rx="6" /><g transform="translate(-390 -857)"><path d="M8.84,3.06A5.787,5.787,0,0,0,3.06,8.84V25.16a5.787,5.787,0,0,0,5.78,5.78H25.16a5.787,5.787,0,0,0,5.78-5.78V8.84a5.787,5.787,0,0,0-5.78-5.78H8.84M8.84,0H25.16A8.84,8.84,0,0,1,34,8.84V25.16A8.84,8.84,0,0,1,25.16,34H8.84A8.84,8.84,0,0,1,0,25.16V8.84A8.84,8.84,0,0,1,8.84,0Z" transform="translate(398 865)" fill="#fff"/><path d="M9,3.24A5.76,5.76,0,1,0,14.76,9,5.767,5.767,0,0,0,9,3.24M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z" transform="translate(406.32 873.32)" fill="#fff"/><circle cx="2.5" cy="2.5" r="2.5" transform="translate(421.387 870.613)" fill="#fff"/></g>
                </svg>
            </a>
        </div>
    {/if}

    <!-- HOME_PAGE Link (opens in iframe within Liveticker app!) -->
    {#if LINKS.hasOwnProperty('HOME_PAGE') && LINKS.HOME_PAGE !== ''}
        <div>
            <a
                    href={LINKS.HOME_PAGE}
                    alt={'Corris ' + locDesc.home_page}
                    title={'Corris ' + locDesc.home_page}
                    on:click|preventDefault={handleClick}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <rect width="50" height="50" rx="6" fill="#35a4a7"/><circle cx="10" cy="10" r="10" transform="translate(15 21)" fill="#fff"/><path d="M2.068,0,.286-7.678H1.738L2.5-3.993,2.97-1.639H3l.55-2.354.88-3.685H6.039l.88,3.685.539,2.354h.033l.484-2.354.781-3.685h1.386L8.305,0H6.666L5.687-4.059,5.214-6.083H5.192L4.7-4.059,3.718,0Zm10.78,0L11.066-7.678h1.452l.759,3.685.473,2.354h.033l.55-2.354.88-3.685h1.606l.88,3.685.539,2.354h.033l.484-2.354.781-3.685h1.386L19.085,0H17.446l-.979-4.059-.473-2.024h-.022l-.5,2.024L14.5,0Zm10.78,0L21.846-7.678H23.3l.759,3.685.473,2.354h.033l.55-2.354.88-3.685H27.6l.88,3.685.539,2.354h.033l.484-2.354.781-3.685H31.7L29.865,0H28.226l-.979-4.059-.473-2.024h-.022l-.495,2.024L25.278,0Z" transform="translate(9 15)" fill="#fff"/>
                </svg>
            </a>
        </div>
    {/if}
</div>
