<script>

    export let color;
    // export let darkColor;
    // export let isActive;

</script>

<style>

  .circle-1 {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 1px solid #757474;
    border-radius: 11px;
    background-color: #989797;
  }

</style>


<div
        class="circle-1"
        style="background-color:{color};"
></div>