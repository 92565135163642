<script>

    import {onMount} from "svelte";

    import App from './App.svelte';

    let isMapboxGLSupport = false;
    let mapboxGLSupportErrorMsg = '';

    let isAppMainReady =false;
    let showApp = false;

    onMount(() => {

        try {

            isMapboxGLSupport = mapboxgl && mapboxgl.supported();

            showApp = !APP_CONFIG.SHOW_DEBUG_PAGE;
            isAppMainReady = true;
        }
        catch (ex) {

            mapboxGLSupportErrorMsg = ex;
        }
    });

</script>

<style>

  .layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f3f2f2;
  }

  .layout > div {
    padding: 16px;
  }

  span.error {
    display: block;
    padding: 7px;
    color: red;
    background-color: white;
  }

  .try-btn {
    text-align: center;
    padding: 48px;
  }

  .try-btn button {
    padding: 18px 24px;
  }


</style>

{#if ((APP_CONFIG.SHOW_DEBUG_PAGE || mapboxGLSupportErrorMsg) !== '') && !showApp}

    <div class="layout">

        <div>
            Liveticker Svelte AppMain: <strong>OKAY</strong>
        </div>

        <div>

            MapboxGL Support:&nbsp;
            <strong>
            {isMapboxGLSupport ? 'OKAY' : 'NOT SUPPORTED'}
            </strong>

        </div>

        <div>

            {#if mapboxGLSupportErrorMsg !== ''}

                <span class="error">
                    <strong>{mapboxGLSupportErrorMsg}</strong>
                </span>

            {/if}

        </div>

    </div>


    <div class="try-btn">

        <button
                type="button"
                on:click={() => showApp = true}
        >
            Continue To Liveticker App
        </button>

    </div>

{:else if isAppMainReady}

    <App
        {isMapboxGLSupport}
    />

{/if}
