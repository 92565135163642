<script>

    import {createEventDispatcher} from 'svelte';

    export let clientCode;
    export let color;
    export let logoWidth;
    export let logoHeight;
    export let name;
    export let logoURL;
    // export let count;
    // export let isActive;
    export let isPlaceholder = false;
    export let locDesc;

    const dispatch = createEventDispatcher();

    function handleLogoClick(nextClientCode) {

        dispatch('client_code_change', {
            nextClientCode
        });
    }

</script>

<style>

  /* Item of pair flexbox */

  button,
  .button-placeholder {
    width: 136px;
    height: 72px;
    margin: 6px 7px;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: white;
  }

  .border-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 3px solid red;
    border-radius: 6px;
  }

  button img,
  button .no-logo {
    width: 96%;
    /*border: 1px solid violet;*/
  }

  button > div {
    background-color: white;
  }

  button span {
    display: block;
    font-size: 1.4rem;
  }

  button .no-logo {
    font-size: 0.8rem;
    font-weight: 300;
    white-space: normal;
  }

  button:hover {
    border-color: #545454;
  }

  .button-placeholder {
    background-color: transparent;
  }

</style>

{#if !isPlaceholder}

    <button
            type="button"
            style="width:{logoWidth}px;height:{logoHeight}px"
            value={clientCode}
            title={name}
            on:click|stopPropagation ={() => handleLogoClick(clientCode)}
    >

        {#if logoURL !== ''}

            <div
                    class="border-box"
                    style="border-color:{color}"
            >
                <img
                        src="{logoURL}"
                        alt={name}
                />
            </div>

        {:else}

            <div class="no-logo">
                {name}
                <span>({locDesc.no_logo_file_url})</span>
            </div>

        {/if}

    </button>

{:else}

    <div
            class="button-placeholder"
            style="width:{logoWidth}px;height:{logoHeight}px"
    >&nbsp;</div>

{/if}
