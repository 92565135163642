<script>

    import {intTo2DigitLabel} from '../../services/util';
    
    import {layout} from "../../store";

    export let isAltLayout;
    // export let leftRightMargin;
    // export let dateTimeFontSize;
    export let weekday;
    export let day;
    export let month;
    export let year;
    export let hours;
    export let minutes;
    export let locDesc;

    // `locDesc.weekdays_short` must be an array from Sunday - Saturday, 0 - 6
    $: weekdayLabel = locDesc.weekdays_short[weekday];
    $: dayLabel = intTo2DigitLabel(day);
    $: monthLabel = intTo2DigitLabel(month);
    $: hoursLabel = intTo2DigitLabel(hours);
    $: minutesLabel = intTo2DigitLabel(minutes);

    $: dateLabel = `${weekdayLabel} ${dayLabel}.${monthLabel}.${year}`;
    $: timeLabel = `${hoursLabel}:${minutesLabel}`;

    $: {
        // console.log('dateTimeFontSize: %s', dateTimeFontSize);
    }

</script>

<style>

  .date-time-container {
    color: #35A4A7;
    font-family: "Roboto Mono", monospace;
    font-weight: 400;
    line-height: 1;
    text-align: right;
    margin-top: -2px;
    padding-right: 24px;
    white-space: nowrap;
  }

  /*
   * if normal layout (Style A)
   */

  .date-time-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /*
   * if alternative layout (Style B)
   */

  .alternative-date-time {
    font-size: 2.8rem;
  }

  .alternative-date-time span:first-child {
    padding-right: 9px;
  }

</style>

{#if day}

    <div
            class="date-time-container"
            style="padding-right:{$layout.leftRightMargin}px"

    >

        {#if !isAltLayout}

            <div
                    class="date-time-box"
                    style="width:{$layout.statisticsWidth + 2}px;font-size:{$layout.dateTimeFontSize}rem;"
            >

                <div>{dateLabel}</div>
                <div>{timeLabel}</div>

            </div>

        {:else}

            <div
                    class="alternative-date-time"
                    style="font-size:{$layout.altDateTimeFontSize}rem;"
            >

                <span>{dateLabel}</span>
                <span>{timeLabel}</span>

            </div>

        {/if}

    </div>

{/if}
