<script>

    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let label;
    export let group;
    export let settingsName;
    export let value;

    function handleClick(evt) {

        // Dispatch `settings_change` with a 10ms delay because `bind:`
        // requires some milliseconds until it propagates the current value to
        // the parent app component.

        setTimeout(() => {
            dispatch('settings_change', {
                settingsName,
                value
            });
        }, 10);
    }

</script>

<style>

  label {
    padding: 18px 24px 16px 0;
    white-space: nowrap;
    /*border: 1px solid red;*/
  }

  input {
   margin-right: 2px;
  }


</style>

<label>

    <input
            type="radio"
            bind:group={group}
            {value}
            on:click={handleClick}
    />

    {label}

</label>
